import { Divider, Modal, Pagination, Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { gettournamentDetails } from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime } from "../../../utils";
import EditTournament from "./EditTournament";

const TournametDetails = () => {
  const [tournamentData, settournamentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [openWinningSlab, setOpenWinningSlab] = useState(false);

  function fetchTournamentDetails() {
    gettournamentDetails(`${true}&page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          settournamentData(response.data.data);
          setCurrentPage(response.data.page);
          setTotalPages(response.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchTournamentDetails(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const handleOk = () => {
    setOpenWinningSlab(false);
  };
  const handleCancel = () => {
    setOpenWinningSlab(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, record) => {
        return <Tag title={record?._id}>{record.name}</Tag>;
      },
    },
    {
      title: "Minimum Entry FEE (₹)",
      dataIndex: "min_entry_fee",
      key: "min_entry_fee",
    },
    {
      title: "Maximum Entry FEE (₹)",
      dataIndex: "max_entry_fee",
      key: "max_entry_fee",
    },
    {
      title: "Commission fee",
      dataIndex: "commission_fee_percentage",
      key: "commission_fee_percentage",
      render: (data, record) => (
        <Tag
          className=" bg-rgbaprimary7 text-white rounded-md justify-center flex text-center cursor-pointer"
          onClick={() => {
            setOpenWinningSlab(true);
            setFilldata(record);
          }}
        >
          View
        </Tag>
      ),
    },

    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return (
          <p className="text-xs">
            {ExtractDate(record.createdAt)} • {ExtractTime(record.createdAt)}
          </p>
        );
      },
    },
    {
      title: "Live Status",
      dataIndex: "is_live",
      key: "is_live",
      render: (data, record) => {
        return record.is_live === true ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Live
          </Tag>
        ) : (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Not Available
          </Tag>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (data, record) => (
        <p
          className=" text-white rounded-md justify-center text-center w-1/2 cursor-pointer"
          onClick={() => {
            console.log("Cursor", record);

            setFilldata(record);
            setOpenModal(true);
          }}
        >
          ✎
        </p>
      ),
    },
  ];
  const columnsModal = [
    {
      title: "Minimum Entry Fee",
      dataIndex: "min_entry_fee",
      key: "min_entry_fee",
      render: (data, record) => (
        <Tag className=" text-white bg-rgbaprimary7 rounded-md justify-center text-center w-1/2 ">
          {record.min_entry_fee}
        </Tag>
      ),
    },
    {
      title: "Maximum Entry Fee",
      dataIndex: "max_entry_fee",
      key: "max_entry_fee",
      render: (data, record) => (
        <Tag className=" text-white bg-rgbaprimary7 rounded-md justify-center text-center w-1/2 ">
          {record.max_entry_fee}
        </Tag>
      ),
    },
    {
      title: "Raferrer Commission Percentage",
      dataIndex: "raferrer_commission_percentage",
      key: "raferrer_commission_percentage",
      render: (data, record) => (
        <Tag className=" text-white bg-rgbaprimary7 rounded-md justify-center text-center w-1/2 ">
          {record.raferrer_commission_percentage}%
        </Tag>
      ),
    },
    
    {
      title: "Admin Commission Percentage",
      dataIndex: "admin_commission_percentage",
      key: "admin_commission_percentage",
      render: (data, record) => (
        <Tag className=" text-white bg-rgbaprimary7 rounded-md justify-center text-center w-1/2 ">
          {record.admin_commission_percentage}%
        </Tag>
      ),
    },
    {
      title: " Commission Fee Percentage",
      dataIndex: "commission_fee_percentage",
      key: "commission_fee_percentage",
      render: (data, record) => (
        <Tag className=" text-white bg-rgbaprimary7 rounded-md justify-center text-center w-1/2 ">
          {record.commission_fee_percentage}%
        </Tag>
      ),
    },
  ];
  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Tournament Details
            </div>

            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                Live Tournaments Details
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                bordered={true}
                columns={columns}
                dataSource={tournamentData}
                pagination={false}
                className="w-screen sm:w-screen "
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
            {openWinningSlab && (
              <Modal
                okButtonProps={{ className: "bg-rgbaprimary9" }}
                title="Commision Slab"
                open={openWinningSlab}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Table
                  columns={columnsModal}
                  dataSource={filldata?.commission_rates ?? []}
                  pagination={false}
                  bordered
                  size="small"
                  rowClassName="table-row"
                />
              </Modal>
            )}
            {openModal && (
              <EditTournament
                isOpen={openModal}
                setIsOpen={setOpenModal}
                Fill={filldata}
                setFill={setFilldata}
                apiCall={fetchTournamentDetails}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};
export default TournametDetails;
