import {
  Button,
  Divider,
  Image,
  Pagination,
  Popconfirm,
  Select,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getFundDetails,
  updateFundStatus,
} from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime, messageConfiguration } from "../../../utils";
import AddFund from "./AddFund";
import { useNavigate } from "react-router-dom";

const FundRequests = () => {
  const [fundData, setFundData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState("add");
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const [actionConfirmed, setActionConfirmed] = useState(false);
  const navigate = useNavigate();
  function FundRequestsDetails(currentPage) {
    getFundDetails(`?page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setFundData(response.data.data);
          setCurrentPage(response.data.pagination.page);
          setTotalPages(response.data.pagination.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }
  function changeFundStatus(checked, record) {
    const payload = {
      fundReqId: record._id,
      status: checked,
      remark: remark,
    };
    updateFundStatus(payload)
      .then((response) => {
        if (response.data.status) {
          message.open(
            messageConfiguration("success", response.data.message, 3)
          );
          FundRequestsDetails(currentPage);
          // setActionConfirmed(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log(`${process.env.BASE_URL}`);
  useEffect(() => {
    FundRequestsDetails(currentPage);
  }, [currentPage]);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const setwithdrawAction = (id, value) => {
    setAction((prevAction) => ({
      ...prevAction,
      [id]: value,
    }));
  };
  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (data, record) => {
        return (
          <Tag className="text-xs flex justify-center bg-rgbaprimary2 border-rgbaprimary5">
            {record?.user_id?.name}
          </Tag>
        );
      },
    },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      key: "mobile",
      render: (data, record) => {
        return (
          <Tag className="text-xs flex justify-center bg-rgbaprimary2 border-rgbaprimary5">
            {record?.user_id?.mobile}
          </Tag>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "UTR Number",
      dataIndex: "utrNumber",
      key: "utrNumber",
      render: (data, record) => {
        return (
          <>
            <Tag className=" bg-rgbaprimary2 border-rgbaprimary5 flex justify-center">
              {record.utrNumber ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",

      key: "remarks",
      render: (data, record) => {
        return (
          <>
            <Tag className=" bg-rgbaprimary2 border-rgbaprimary5 flex justify-center">
              {record.remarks ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return (
          <p className="text-xs">
            {ExtractDate(record.createdAt)}•{ExtractTime(record.createdAt)}
          </p>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (data, record) => (
        // console.log(`http://localhost:3030/${record.image}`)
        <Image
          width={50}
          height={50}
          src={`${process.env.REACT_APP_IMAGE_URL}${record.image}`}

          // className="bg-rgbaprimary9 text-white rounded-md justify-center text-center  cursor-pointer"
          // onClick={() => {
          //   navigate("/customers/viewDetail", {
          //     state: {
          //       Fill: record,
          //     },
          //   });
          // }}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, record) => {
        return record.status === "approved" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Approved
          </Tag>
        ) : record.status === "rejected" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Rejected
          </Tag>
        ) : (
          <Tag>Under Process</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (data, record) => {
        return (
          <div>
            <Select
              // disabled={record.status !== "under_process"}
              placeholder={record.status}
              style={{
                width: 120,
              }}
              onChange={(value) => setwithdrawAction(record._id, value)}
              value={action[record._id]}
            >
              <Select.Option value="approved">Approved</Select.Option>
              <Select.Option value="reject">Rejected</Select.Option>
            </Select>
            {action[record._id] === "reject" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Enter Remark
                    </p>
                    <input
                      type="text"
                      value={remark[record._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                }
                onConfirm={() => changeFundStatus("reject", record)}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button type="link">Enter Remark</Button>
              </Popconfirm>
            )}
            {action[record._id] === "approved" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Enter Remark
                    </p>
                    <input
                      type="text"
                      value={remark[record._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                }
                onConfirm={() => changeFundStatus("approved", record)}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button type="link">Enter Remark</Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Fund Requests
            </div>
            <div className="flex items-end justify-between">
              <p
                onClick={() => {
                  navigate("/fundHistory");
                }}
                className="bg-rgbaprimary9 cursor-pointer text-white text-xs p-4 rounded-md hover:bg-rgbaprimary5"
              >
                Show Fund History
              </p>
              <p
                onClick={() => {
                  navigate("/completeRechargeHistory");
                }}
                className="bg-rgbaprimary9 cursor-pointer text-white text-xs p-4 rounded-md hover:bg-rgbaprimary5"
              >
                Complete Recharge History
              </p>
            </div>

            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                Fund Details
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                bordered={true}
                columns={columns}
                dataSource={fundData}
                pagination={false}
                className="w-screen sm:w-screen "
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
            {openModal && (
              <AddFund
                isOpen={openModal}
                setIsOpen={setOpenModal}
                Fill={filldata}
                setFill={setFilldata}
                from={from}
                apiCall={FundRequestsDetails}
              />
            )}
          </>
        )}
      </>
    </div>
  );
};
export default FundRequests;
