import { Divider, Input, Pagination, Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { getSearchTodayWithdrawRequests, getTodayWithdrwalDetails } from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime } from "../../../utils";
import moment from "moment";

const TodayWithdrawl = () => {
  const [todayWithdrawl, setTodayWithdraw] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const { Search } = Input;
  function TodayWithdrwalDetails() {
    getTodayWithdrwalDetails(`?page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.data.success) {
          setTodayWithdraw(response.data?.data);
          setCurrentPage(response.data?.currentPage);
          setTotalPages(response.data?.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }
  // console.log("Today withdrwal details",todayWithdrawl)
  useEffect(() => {
    TodayWithdrwalDetails(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "User ",
      dataIndex: "User",
      key: "User",
      render: (data, record) => {
        return <Tag className="text-xs bg-rgbaprimary3" title={record.user_id?._id}>{record.user_id?.name}</Tag>;
      },
    },
    {
        title: "Contact number ",
        dataIndex: "mobile",
        key: "mobile",
        render: (data, record) => {
          return <Tag className="text-xs bg-rgbaprimary3">{record.user_id?.mobile}</Tag>;
        },
      },
    {
      title: "Amount (₹)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (data, record) => {
        return record.remarks === null ? (
          <Tag className="text-xs flex justify-center bg-rgbaprimary3">----</Tag>
        ) : (
          <Tag className="text-xs flex justify-center bg-rgbaprimary3">{record.remarks}</Tag>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => (
        <p className="rounded-md justify-center text-center">
          {ExtractDate(record.createdAt)} • {ExtractTime(record.createdAt)}
        </p>
      ),
    },
    {
      title: "Updated Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (data, record) => (
        <p className="rounded-md justify-center text-center">
          {ExtractDate(record.updatedAt)} • {ExtractTime(record.updatedAt)}
        </p>
      ),
    },
    // {
    //     title: "Created Time",
    //     render: (data, record) => {
    //       const createdDate = moment(record?.createdAt);
    //       const updatedDate = moment(record?.updatedAt);
  
    //       const daysDifference = updatedDate?.diff(createdDate, "days");
    //       const timeDifference = moment
    //         .utc(updatedDate?.diff(createdDate))
    //         .format("HH:mm:ss");
  
    //       return (
    //         <div>
    //           <Tag className="bg-rgbaprimary3">
    //             {" "}
    //             {daysDifference} days {timeDifference} hrs
    //           </Tag>
    //         </div>
    //       );
    //     },
    //   },
   
  ];
  useEffect(() => {
    if (searchTerm === "") {
      TodayWithdrwalDetails(currentPage);
    } else {
      getSearchTodayWithdrawRequests(`${searchTerm}&pagesize=${10}`)
        .then((response) => {
          if (response.data.status) {
            console.log(response);
            setTodayWithdraw(response.data?.data);
            // const mailData = response.data.data.map((item) => item.email_id)
            // dispatch(setContactMail(mailData))
            // setShowSkeleton(false)
            setTotalPages(response.data?.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm, currentPage]);
  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Today Withdrawal
            </div>
            <div className="flex items-end justify-between">
              <Search
                placeholder="search using contact , name, email here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
            </div>
            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                Today Withdrawal
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                bordered={true}
                columns={columns}
                dataSource={todayWithdrawl}
                pagination={false}
                className="w-screen sm:w-screen "
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};
export default TodayWithdrawl;
