import { Card, Divider, Spin, message, Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  deleteNews,
  getFundNews,
  getGeneralNews,
  getTournamentNews,
  getWithdrawalNews,
} from "../../../services/apiFunctions";
import CreateNews from "./AddNews";
import { EditOutlined } from "@ant-design/icons";
import { MdDelete, MdEdit } from "react-icons/md";
import AddTournamentNews from "./AddTournamentNews";
import { GiBullets } from "react-icons/gi";
import { messageConfiguration } from "../../../utils";
import AddWithdrawalNews from "./AddWithdrawal";
import AddFundNews from "./AddFundNews";

const News = () => {
  const [generalNews, setGeneralNews] = useState([]);
  const [tournamentNews, setTournamentNews] = useState([]);
  const [withdrawalNews, setWithdrawalNews] = useState([]);
  const [fund_news, setFundNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openFundModal, setOpenFundModal] = useState(false);
  const [opentournamentModal, setOpentournamentModal] = useState(false);
  const [openwithdrawalModal, setOpenWithdrwalModal] = useState(false);
  const [totalGeneralPage, setTotalGeneralPages] = useState();
  const [totalTournamentPage, setTotalTournamentPages] = useState();
  const [currentGenralPage, setCurrentGeneralPage] = useState(1);
  const [currentTournamentPage, setCurrentTournamentPage] = useState(1);
  const [currentWithdrawalPage, setCurrentWithdrawalPage] = useState(1);
  const [currentFundPage, setCurrentFundPage] = useState(1);
  const [from, setFrom] = useState("add");

  useEffect(() => {
    GeneralNews(currentGenralPage);
  }, [currentGenralPage]);

  useEffect(() => {
    TournamentNews(currentTournamentPage);
  }, [currentTournamentPage]);

  useEffect(() => {
    WithdrawalNews(currentWithdrawalPage);
  }, [currentWithdrawalPage]);

  useEffect(() => {
    FundNews(currentFundPage);
  }, [currentFundPage]);
  const GeneralNews = () => {
    getGeneralNews(`&page=${currentGenralPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setGeneralNews(response.data.data);
          setCurrentGeneralPage(response.data.pagination.page);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const TournamentNews = () => {
    getTournamentNews(`&page=${currentTournamentPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setTournamentNews(response.data.data);
          setCurrentTournamentPage(response.data.pagination.page);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const WithdrawalNews = () => {
    getWithdrawalNews(`&page=${currentWithdrawalPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setWithdrawalNews(response.data?.data);
          setCurrentWithdrawalPage(response.data?.pagination?.page);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const FundNews = () => {
    getFundNews(`&page=${currentFundPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setFundNews(response.data?.data);
          setCurrentFundPage(response.data?.pagination?.page);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const DeleteNews = (id, type) => {
    deleteNews(id, type)
      .then((response) => {
        if (response.data.success) {
          message.open(
            messageConfiguration("success", response.data.message, 3)
          );
          {
            type === "tournament_news"
              ? TournamentNews()
              : type === "withdraw_news"
              ? WithdrawalNews()
              : GeneralNews();
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const renderNewsCard = (title, newsList, setOpenModalFunction, setFillFunction, setFromFunction) => (
    <div style={{ width: "100%", marginBottom: "20px" }}>
      <Card title={title}>
        {newsList.length === 0 ? (
          <p>No data</p>
        ) : (
          newsList.map((i, index) => (
            <div
              className="flex items-center justify-between my-2"
              key={index}
            >
              <p className="text-gray-500 mb-2">✦ {i.news_Description}</p>
              <div className="flex items-center">
                <MdEdit
                  title="Edit"
                  className="cursor-pointer text-lg text-gray-300 mr-2"
                  onClick={() => {
                    setOpenModalFunction(true);
                    setFromFunction("edit");
                    setFillFunction(i);
                  }}
                />
                <MdDelete
                  title="Delete"
                  className="cursor-pointer text-lg text-red-300"
                  onClick={() => {
                    DeleteNews(i._id, i.news_Type);
                  }}
                />
              </div>
            </div>
          ))
        )}
        <Button
          // type="primary"
          className="bg-primary text-white"
          onClick={() => {
            setOpenModalFunction(true);
            setFromFunction("add");
          }}
        >
          Add News
        </Button>
      </Card>
    </div>
  );

  return (
    <div>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            News
          </div>
      
          {renderNewsCard("General News Board", generalNews, setOpenModal, setFilldata, setFrom)}
          {renderNewsCard("Tournament News Board", tournamentNews, setOpentournamentModal, setFilldata, setFrom)}
          {renderNewsCard("Withdrawal News Board", withdrawalNews, setOpenWithdrwalModal, setFilldata, setFrom)}
          {renderNewsCard("Fund News Board", fund_news, setOpenFundModal, setFilldata, setFrom)}
          
          {openModal && (
            <CreateNews
              isOpen={openModal}
              setIsOpen={setOpenModal}
              Fill={filldata}
              setFill={setFilldata}
              apiCall={GeneralNews}
              from={from}
            />
          )}
          {openwithdrawalModal && (
            <AddWithdrawalNews
              isOpen={openwithdrawalModal}
              setIsOpen={setOpenWithdrwalModal}
              Fill={filldata}
              setFill={setFilldata}
              apiCall={WithdrawalNews}
              from={from}
            />
          )}
          {opentournamentModal && (
            <AddTournamentNews
              isOpen={opentournamentModal}
              setIsOpen={setOpentournamentModal}
              Fill={filldata}
              setFill={setFilldata}
              apiCall={TournamentNews}
              from={from}
            />
          )}
          {openFundModal && (
            <AddFundNews
              isOpen={openFundModal}
              setIsOpen={setOpenFundModal}
              Fill={filldata}
              setFill={setFilldata}
              apiCall={FundNews}
              from={from}
            />
          )}
        </>
      )}
    </div>
  );
};

export default News;
