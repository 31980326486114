import {
  Button,
  Divider,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Spin,
  Table,
  Tag,
  Input,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getSearchWithdrawRequests,
  getwithdrawDetails,
  updateWithdrawStatus,
} from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime, messageConfiguration } from "../../../utils";

const WithdrawRequests = () => {
  const [fundData, setFundData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState([]);
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const [openBankDetails, setOpenBAnkDetails] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState("add");
  const [actionConfirmed, setActionConfirmed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { Search } = Input;
  function WithdrawRequestsDetails() {
    getwithdrawDetails(`&page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setFundData(response.data.data);
          setCurrentPage(response.data?.currentPage);
          setTotalPages(response.data?.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }
  function changeWithdrawStatus(checked, record) {
    const payload = {
      withDrawReqId: record._id,
      status: checked,
      remark: remark,
    };
    updateWithdrawStatus(payload)
      .then((response) => {
        if (response.data.status) {
          message.open(
            messageConfiguration("success", response.data.message, 3)
          );
          WithdrawRequestsDetails(currentPage);
          // setActionConfirmed(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    WithdrawRequestsDetails(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  const setwithdrawAction = (id, value) => {
    setAction((prevAction) => ({
      ...prevAction,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (searchTerm === "") {
      WithdrawRequestsDetails(currentPage);
    } else {
      getSearchWithdrawRequests(`${searchTerm}&pagesize=${10}`)
        .then((response) => {
          if (response.data.status) {
            console.log(response);
            setFundData(response.data?.data);
            // const mailData = response.data.data.map((item) => item.email_id)
            // dispatch(setContactMail(mailData))
            // setShowSkeleton(false)
            setTotalPages(response.data?.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm, currentPage]);

  const columns = [
    {
      title: "User",
      dataIndex: "user_id",
      key: "user_id",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-rgbaprimary2 border-rgbaprimary5"
              title={record.user_id?._id}
            >
              {record.user_id?.name?.toUpperCase() ?? "--"}
            </Tag>
            <Tag>{record.user_id?.mobile ?? "--"}</Tag>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, record) => {
        return record.status === "approved" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Approved
          </Tag>
        ) : record.status === "rejected" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Rejected
          </Tag>
        ) : (
          <Tag>Under Process</Tag>
        );
      },
    },
    {
      title: "Bank Details",
      dataIndex: "bank_detail",
      key: "bank_detail",
      render: (data, record) => {
        return (
          <p
            onClick={() => {
              setOpenBAnkDetails(true);
              setFilldata(record?.withdrawOption);
              console.log(record?.withdrawOption);
            }}
            className="bg-rgbaprimary9 p-1 cursor-pointer text-white text-center rounded-md hover:bg-rgbaprimary5"
          >
            View
          </p>
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (data, record) => {
        return record.remarks === null ? (
          <p className="text-xs flex justify-center">___</p>
        ) : (
          <p className="text-xs">{record.remarks}</p>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return (
          <p className="text-xs">
            {ExtractDate(record.createdAt)} （{ExtractTime(record.createdAt)}）
          </p>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (data, record) => {
        return (
          <div>
            <Select
              disabled={record.status !== "under_process"}
              placeholder={record.status}
              style={{
                width: 120,
              }}
              onChange={(value) => setwithdrawAction(record._id, value)}
              value={action[record._id]}
            >
              <Select.Option value="approved">Approved</Select.Option>
              <Select.Option value="rejected">Rejected</Select.Option>
            </Select>
            {action[record._id] === "rejected" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Enter Remark
                    </p>
                    <input
                      type="text"
                      value={remark[record._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                }
                onConfirm={() => changeWithdrawStatus("rejected", record)}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button  type="link">
                  Enter Remark
                </Button>
              </Popconfirm>
            )}
            {action[record._id] === "approved" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Enter Remark
                    </p>
                    <input
                      type="text"
                      value={remark[record._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                }
                onConfirm={() => changeWithdrawStatus("approved", record)}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button  type="link">
                  Enter Remark
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const handleOk = () => {
    setOpenBAnkDetails(false);
  };

  const handleCancel = () => {
    setOpenBAnkDetails(false);
  };

  const columnsModal = [
    {
      title: "Information Type",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const data = [
    {
      key: "1",
      field: "Account Holder Name",
      value: filldata?.Details?.ac_holder_name,
    },
    { key: "2", field: "Account Number", value: filldata?.Details?.ac_no },
    { key: "3", field: "Bank Name", value: filldata?.Details?.bank },
    { key: "4", field: "IFSC Code", value: filldata?.Details?.ifsc },
    { key: "5", field: "Amount", value: filldata?.Details?.amount },
  ];
  const dataUPI = [
    { key: "1", field: "UPI ID", value: filldata?.Details?.upi_id },
  ];
  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Withdraw Requests
            </div>
            <div className="flex items-end justify-between">
              <Search
                placeholder="search  here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
            </div>
            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                Withdraw Details
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                bordered={true}
                columns={columns}
                dataSource={fundData}
                pagination={false}
                className="w-screen sm:w-screen"
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>

            {openBankDetails && (
              <Modal
                okButtonProps={{ className: "bg-rgbaprimary9" }}
                title="Bank Details"
                open={openBankDetails}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                {filldata?.withdrawOption === "netBank" ? (
                  <Table
                    columns={columnsModal}
                    dataSource={data}
                    pagination={false}
                    // bordered
                    size="small"
                    rowClassName="table-row"
                  />
                ) : (
                  <Table
                    columns={columnsModal}
                    dataSource={dataUPI}
                    pagination={false}
                    // bordered
                    size="small"
                    rowClassName="table-row"
                  />
                )}
              </Modal>
            )}
          </>
        )}
      </>
    </div>
  );
};
export default WithdrawRequests;
