import React, { useRef } from "react";
import { message, Button, Form, Input, Modal } from "antd";
import { addnews, news_edit } from "../../../services/apiFunctions";

const CreateNews = ({ isOpen, setIsOpen, apiCall, from, Fill }) => {
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const { TextArea } = Input;
console.log(Fill,"___________________________________________________")
  const createNews = (changedValues) => {
    const payload = {
      description: changedValues.news,
      type:"General_news",
    };
    addnews(payload)
      .then((response) => {
        if (response.data.success) {
        message.success({ content: response.data.message })
         
          apiCall();
          setIsOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const editNews = (changedValues) => {
    const payload = {
      description: changedValues.news,
      newId:Fill._id,
    };
    news_edit(payload)
      .then((response) => {
        if (response.data.success) {
           message.success({ content: "News Edited Successfully" });
          
          apiCall();
          setIsOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        createNews(values);
        setIsOpen(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        footer={null}
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={`${from === "add" ? "Add" : "Edit"} General News`}
      >
        <Form
          className="bg-white p-6"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={  from === "add"
          ? createNews
          : editNews}
          form={form}
          ref={formRef}
          initialValues={
            from === "add"
              ? {}
              : {
                  news: Fill.news_Description,
                }
          }
        >
          <Form.Item
            name="news"
            label="News"
            rules={[
              {
                required: true,
                message: "Please enter News",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.split(/\s+/).length <= 250) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("News text limit is 250 words")
                  );
                },
              }),
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <div>
            <Form.Item className="flex justify-center mt-4">
              <Button type="primary" htmlType="onSubmit">
                {from === "edit" ? "Edit" : "Add "}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateNews;
