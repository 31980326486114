import { Divider, Pagination, Spin, Table, Tag, Input } from "antd";
import React, { useEffect, useState } from "react";
import {
  getRoomCodeLiveBattle,
  getSearchLiveBattle,
  getliveBattleDetails,
  gettournamentDetails,
} from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime } from "../../../utils";

const LiveBattles = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  const [roomCode, setRoomCode] = useState("");
  const { Search } = Input;
  function liveBattlesDetails() {
    getliveBattleDetails(`&page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setData(response.data?.data);
          setCurrentPage(response.data.page);
          setTotalPages(response.data.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    if (searchTerm === "") {
      liveBattlesDetails(currentPage);
    } else {
      getSearchLiveBattle(`&searchString=${searchTerm}&page=${currentPage}&pagesize=${10}`)
        .then((response) => {
          if (response.data.status) {
            console.log(response);
            setData(response.data?.data);
            setTotalPages(response.data?.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm, currentPage]);
  useEffect(() => {
    if (roomCode === "") {
      liveBattlesDetails(currentPage);
    } else {
      getRoomCodeLiveBattle(
        `${roomCode}&pagesize=${10}`
      )
        .then((response) => {
          if (response.data.status) {
            console.log(response);
            setData(response.data?.data);
            setTotalPages(response.data?.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [roomCode, currentPage]);
  useEffect(() => {
    liveBattlesDetails(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-green-300 border-green-500"
              title={record.created_by?.user_id}
            >
              {record.created_by?.userName?.toUpperCase()}
            </Tag>
            <Tag>{record.created_by?.user_mobile ?? "--"}</Tag>
          </>
        );
      },
    },
    {
      title: "Joined By",
      dataIndex: "joined_by",
      key: "joined_by",
      render: (data, record) => {
        return (
          <>
            {" "}
            <Tag
              className=" bg-rgbaprimary2 border-rgbaprimary5"
              title={record.joined_by?.user_id}
            >
              {record.joined_by?.userName?.toUpperCase()??"--"}
            </Tag>
            <Tag>{record.joined_by?.user_mobile ?? "--"}</Tag>
          </>
        );
      },
    },
    {
      title: "Entry Fee (₹)",
      dataIndex: "entry_fee",
      key: "entry_fee",
    },
    {
      title: "Prize",
      dataIndex: "prize",
      key: "prize",
    },
    {
      title: "Refree Commissions",
      dataIndex: "refree_commission",
      key: "refree_commission",
    },
    {
      title: "Admin Commission(₹)",
      dataIndex: "admin_commission",
      key: "admin_commission",
    },
    {
      title: "Room Code",
      dataIndex: "room_code",
      key: "room_code",
      render: (data, record) => {
        return (
          <Tag className="text-xs flex justify-center bg-rgbaprimary2 border-rgbaprimary5">
            {record.room_code??"---"}
          </Tag>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return (
          <p className="text-xs">
            {ExtractDate(record.createdAt)}• {ExtractTime(record.createdAt)}
          </p>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data, record) => {
        return record.status === "Running" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Running
          </Tag>
        ) : (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Not Running
          </Tag>
        );
      },
    },
    {
      title: "Tournament ",
      dataIndex: "tournamentId",
      key: "tournamentId",
      render: (data, record) => {
        return (
          <Tag
            className=" bg-rgbaprimary2 border-rgbaprimary5"
            title={record.tournamentId?._id}
          >
            {record.tournamentId?.name?.toUpperCase() ?? "--"}
          </Tag>
        );
      },
    },
    // {
    //   title: "Edit",
    //   dataIndex: "edit",
    //   key: "edit",
    //   render: (data, record) => (
    //     <p
    //       className=" text-white rounded-md justify-center text-center w-1/2 cursor-pointer"
    //       onClick={() => {
    //         console.log("Cursor", record);
    //         setFrom("edit");
    //         setFilldata(record);
    //         setOpenModal(true);
    //       }}
    //     >
    //       ✎
    //     </p>
    //   ),
    // },
  ];

  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Live Battles
            </div>
            <div className="flex items-end justify-between">
            <Search
                placeholder="Enter room code..."
                // value={roomCode}
                onChange={(e) => setRoomCode(e.target.value)}
                style={{ width: "25%" }}
              />
              <Search
                placeholder="Enter search term..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
              
            </div>

            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                Live Battles
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                bordered={true}
                columns={columns}
                dataSource={data}
                pagination={false}
                className="w-screen sm:w-screen "
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
            {/* {openModal && (
              <AddFund
                isOpen={openModal}
                setIsOpen={setOpenModal}
                Fill={filldata}
                setFill={setFilldata}
                from={from}
                apiCall={FundRequestsDetails}
              />
            )} */}
          </>
        )}
      </>
    </div>
  );
};
export default LiveBattles;
