import {
  Button,
  Divider,
  Image,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getSearchCancel,
  getSearchIwin,
  getbattleDetails,
  updateActionStatus,
} from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime, messageConfiguration } from "../../../utils";

const BattleRequests = () => {
  const [battleiwinData, setBattleIwinData] = useState([]);
  const [battlecancelData, setBattleCancelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState([]);
  const [openBankDetails, setOpenBAnkDetails] = useState(false);
  const [totalCancelPage, setTotalCancelPages] = useState();
  const [totaIwinPage, setTotalIwinPages] = useState();
  const [currentIwinPage, setCurrentIwinPage] = useState(1);
  const [currentCancelPage, setCurrentCancelPage] = useState(1);
  const [from, setFrom] = useState("add");
  const { Search } = Input;
  const [searchIwinTerm, setSearchIWinTerm] = useState("");
  const [searchCancelTerm, setSearchCancelTerm] = useState("");
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const [actionConfirmed, setActionConfirmed] = useState(false);

  function BattleIwinRequestsDetails(currentIwinPage) {
    getbattleDetails(`${"iwin"}&page=${currentIwinPage}&limit=10`)
      .then((response) => {
        if (response.data.success) {
          setBattleIwinData(response.data.data);
          setCurrentIwinPage(response.data.pagination.currentPage);
          setTotalIwinPages(response.data.pagination.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }

  function BattleCancelRequestsDetails(currentCancelPage) {
    getbattleDetails(`${"cancel"}&page=${currentCancelPage}&limit=10`)
      .then((response) => {
        if (response.data.success) {
          setBattleCancelData(response.data.data);
          setCurrentCancelPage(response.data.pagination.currentPage);
          setTotalCancelPages(response.data.pagination.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    BattleCancelRequestsDetails(currentCancelPage);
    BattleIwinRequestsDetails(currentIwinPage);
  }, [currentCancelPage, currentIwinPage]);

  function changeBattleStatus(checked, record) {
    const payload = {
      status: checked,
      remark: remark,
    };
    updateActionStatus(payload, `${record._id}`)
      .then((response) => {
        if (response.data.success) {
          message.open(
            messageConfiguration("success", response.data.message, 3)
          );
          BattleCancelRequestsDetails(currentCancelPage);
          BattleIwinRequestsDetails(currentIwinPage);
          // setActionConfirmed(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleIwinPageChange = (page, pageSize) => {
    setCurrentIwinPage(page);
  };
  const handleCancelPageChange = (page, pageSize) => {
    setCurrentCancelPage(page);
  };
  const setBattleAction = (id, value) => {
    setAction((prevAction) => ({
      ...prevAction,
      [id]: value,
    }));
  };
  const columns = [
    {
      title: "User Name",
      dataIndex: "user_id",
      key: "user_id",
      render: (data, record) => {
        return (
          <>
            <Tag
              className=" bg-rgbaprimary2 border-rgbaprimary5"
              title={record.user_id?._id}
            >
              {record.user_id?.name?.toUpperCase() ?? "--"}
            </Tag>
            <Tag>{record.user_id?.mobile ?? "--"}</Tag>
          </>
        );
      },
    },
    {
      title: "Battle Prize",
      dataIndex: "battle_prize",
      key: "battle_prize",
    },
    // {
    //   title: "Join Id",
    //   dataIndex: "assignId",
    //   key: "assignId",
    // },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Room Code",
      dataIndex: "room_code",
      key: "room_code",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return (
          <p className="text-xs">
            {" "}
            {ExtractDate(record.createdAt)}• {ExtractTime(record.createdAt)}
          </p>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (data, record) => {
        return record.image === null ? (
          <p>No image</p>
        ) : (
          // console.log(`${process.env.REACT_APP_IMAGE_URL}${record.image}`)
          <Image
            width={50}
            height={50}
            src={`${process.env.REACT_APP_IMAGE_URL}${record.image}`}
          />
        );
      },
    },
    {
      title: "Status",
      dataIndex: "request_status",
      key: "request_status",
      render: (data, record) => {
        return record.request_status === "approved" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Approved
          </Tag>
        ) : record.request_status === "rejected" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Rejected
          </Tag>
        ) : (
          <Tag>Under Process</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (data, record) => {
        return (
          <div>
            <Select
              // disabled={record.request_status !== "under_process"}
              placeholder={record.request_status}
              style={{
                width: 120,
              }}
              onChange={(value) => setBattleAction(record._id, value)}
              value={action[record._id]}
            >
              <Select.Option value="approved">Approved</Select.Option>
              <Select.Option value="rejected">Rejected</Select.Option>
            </Select>
            {action[record._id] === "rejected" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Enter Remark
                    </p>
                    <input
                      type="text"
                      value={remark[record._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                }
                onConfirm={() => changeBattleStatus("rejected", record)}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button type="link">Enter Remark</Button>
              </Popconfirm>
            )}
            {action[record._id] === "approved" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Enter Remark
                    </p>
                    <input
                      type="text"
                      value={remark[record._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                }
                onConfirm={() => changeBattleStatus("approved", record)}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button type="link">Enter Remark</Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
  const handleOk = () => {
    setOpenBAnkDetails(false);
  };
  const handleCancel = () => {
    setOpenBAnkDetails(false);
  };

  useEffect(() => {
    if (searchIwinTerm === "") {
      BattleIwinRequestsDetails(currentIwinPage);
    } else {
      getSearchIwin(`${searchIwinTerm}&pagesize=${10}&action=iwin`)
        .then((response) => {
          if (response.status) {
            console.log(response);
            setBattleIwinData(response.data.data);
            // const mailData = response.data.data.map((item) => item.email_id)
            // dispatch(setContactMail(mailData))
            // setShowSkeleton(false)
            setTotalIwinPages(response.data.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchIwinTerm, currentIwinPage]);

  useEffect(() => {
    if (searchCancelTerm === "") {
      BattleCancelRequestsDetails(currentCancelPage);
    } else {
      getSearchCancel(`${searchCancelTerm}&pagesize=${10}&action=cancel`)
        .then((response) => {
          if (response.status) {
            console.log(response);
            setBattleCancelData(response.data.data);
            // const mailData = response.data.data.map((item) => item.email_id)
            // dispatch(setContactMail(mailData))
            // setShowSkeleton(false)
            setTotalCancelPages(response.data.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchCancelTerm, currentCancelPage]);
  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Battle Requests
            </div>
            <div className=" justify-between gap-4 grid md:grid-cols-2 sm:grid-cols-1">
              <div>
                <div>
                  <Divider
                    orientation="center"
                    className="fs-4  theme-darkt fw-semi-bold"
                  >
                    IWin Action Details
                  </Divider>
                </div>

                <div className="flex items-end justify-center m-2">
                  <Search
                    placeholder="search Iwin data here..."
                    value={searchIwinTerm}
                    onChange={(e) => setSearchIWinTerm(e.target.value)}
                    style={{ width: "50%" }}
                  />
                </div>
                <div
                  style={{ maxHeight: "500px", overflow: "auto" }}
                  className="bg-white"
                >
                  <Table
                    bordered={true}
                    columns={columns}
                    dataSource={battleiwinData}
                    pagination={false}
                    // className="w-1/12 sm:w-1/12"
                    size="small"
                  />
                  <Pagination
                    className="mt-2  w-full "
                    onChange={handleIwinPageChange}
                    current={currentIwinPage}
                    defaultCurrent={1}
                    total={10 * totaIwinPage}
                    showSizeChanger={false}
                  />
                </div>
              </div>

              <div>
                <div>
                  <Divider
                    orientation="center"
                    className="fs-4  theme-darkt fw-semi-bold"
                  >
                    Cancel action Details
                  </Divider>
                </div>
                <div className="flex items-end justify-center m-2 ">
                  <Search
                    placeholder="search Cancel Data here..."
                    value={searchCancelTerm}
                    onChange={(e) => setSearchCancelTerm(e.target.value)}
                    style={{ width: "50%" }}
                  />
                </div>
                <div
                  style={{ maxHeight: "500px", overflow: "auto" }}
                  className="bg-white"
                >
                  <Table
                    bordered={true}
                    columns={columns}
                    dataSource={battlecancelData}
                    pagination={false}
                    // className="w-1/12 sm:w-1/12"
                    size="small"
                  />
                  <Pagination
                    className="mt-2  w-full "
                    onChange={handleCancelPageChange}
                    current={currentCancelPage}
                    defaultCurrent={1}
                    total={10 * totalCancelPage}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </div>

            {openBankDetails && (
              <Modal
                okButtonProps={{ className: "bg-bsprimary" }}
                title="Bank Details"
                open={openBankDetails}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <div className="flex justify-around font-normal h-6 gap-3 ">
                  <p>Account Holder Name</p>
                  <p>{filldata.ac_holder_name}</p>
                </div>
                <div className="flex justify-around font-normal h-6 gap-3 ">
                  {" "}
                  <p>Account Number</p>
                  <p>{filldata.ac_no}</p>
                </div>
                <div className="flex justify-around font-normal h-6 gap-3 ">
                  {" "}
                  <p>Bank Name</p>
                  <p>{filldata.bank}</p>
                </div>
                <div className="flex justify-around font-normal h-6 gap-3 ">
                  {" "}
                  <p>IFSC Code</p>
                  <p>{filldata.ifsc_code}</p>
                </div>
              </Modal>
            )}
          </>
        )}
      </>
    </div>
  );
};
export default BattleRequests;
