import { Divider, Modal, Pagination, Spin, Table, Tag, Input } from "antd";
import React, { useState, useEffect } from "react";
import {
  getSearchHoldBattle,
  getSearchLiveBattle,
  getholdbattles,
} from "../../../services/apiFunctions";

import { ExtractDate, ExtractTime } from "../../../utils";
import { CrownFilled } from "@ant-design/icons";
function HoldBattles() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPages] = useState();
  const [filldata, setFilldata] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { Search } = Input;
  function holdBattlesTable(currentPage) {
    getholdbattles(`?page=${currentPage}&limit=10`)
      .then((response) => {
        setData(response.data?.data);
        setCurrentPage(response.data?.page);
        setTotalPages(response.data?.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  const columnsModal = [
    {
      title: "Information Type",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];
  const dataMore = [
    {
      key: "1",
      field: "Admin commission",
      value: filldata?.admin_commission ?? "--",
    },
    // { key: "2", field: "Entry fee", value: filldata?.entry_fee ?? "--" },
    { key: "3", field: "Prize", value: filldata?.prize ?? "--" },
    {
      key: "4",
      field: "Refree Commission",
      value: filldata?.refree_commission ?? "--",
    },
    { key: "5", field: "Room Code", value: filldata?.room_code ?? "--" },
    { key: "6", field: "Status", value: <Tag className="bg-green-200 text-green-600 border-green-600">{filldata?.status ?? "--"}</Tag> },
    { key: "7", field: "Battle ID", value: <Tag>{filldata?._id ?? "--" }</Tag>},

    // {
    //    key: "8", 
    //    field: "Winner id",
    //    value: <Tag className="bg-yellow-200"> <CrownFilled/>{filldata?.winner_id?.userName ?? "--"}</Tag>} ,
  ];
  const columns = [
    {
      title: "Tournament",
      dataIndex: "tournamentId",
      key: "tournamentId",
      render: (data, record) => {
        return <Tag className="bg-rgbaprimary2">{record?.tournamentId?.name ?? "--"}</Tag>;


      },
    },
    {
      title: "Entry Fee",
      dataIndex: "entry_fee",
      key: "entry_fee",
    },
    {
      title: "Joined By",
      dataIndex: "joined_by",
      key: "joined_by",
      render: (data, record) => {
        return <Tag className="bg-rgbaprimary2">{record?.joined_by?.userName ?? "--"}</Tag>;


      },
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      key: "created_by",
      render: (data, record) => {
        return <Tag className="bg-rgbaprimary2">{record?.created_by?.userName ?? "--"}</Tag>;
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return (
          <p className="text-xs">
            {ExtractDate(record.createdAt)} （{ExtractTime(record.createdAt)}）
          </p>
        );
      },
    },
    // {
    //   title: "Holding Time",
    //   render: (data, record) => {
    //     const createdDate = moment(record?.createdAt);
    //     const updatedDate = moment(record?.updatedAt);

    //     const daysDifference = updatedDate?.diff(createdDate, "days");
    //     const timeDifference = moment
    //       .utc(updatedDate?.diff(createdDate))
    //       .format("HH:mm:ss");

    //     return (
    //       <div>
    //         <Tag className="bg-rgbaprimary3">
    //           {" "}
    //           {daysDifference} days {timeDifference} hrs
    //         </Tag>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "View",
      render: (data, record) => (
        <p
          className="bg-rgbaprimary9 p-1 cursor-pointer text-white text-center rounded-md hover:bg-rgbaprimary5"
          onClick={() => {
            setFilldata(record);
            setOpenModal(true);
          }}
        >
          show more
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (searchTerm === "") {
      holdBattlesTable(currentPage);
    } else {
      getSearchHoldBattle(`&searchString=${searchTerm}&page=${currentPage}&pagesize=${10}`)
        .then((response) => {
          if (response.data.status) {
            console.log(response);
            setData(response.data?.data);
            // const mailData = response.data.data.map((item) => item.email_id)
            // dispatch(setContactMail(mailData))
            // setShowSkeleton(false)
            setTotalPages(response.data?.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm, currentPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    holdBattlesTable(currentPage);
  }, [currentPage]);
  const handleOk = () => {
    setOpenModal(false);
  };
  const handleCancel = () => {
    setOpenModal(false);
  };
  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Hold Battles
            </div>
            <div className="flex items-end justify-between">
              <Search
                placeholder="search users here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
            </div>

            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                Hold Battles
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="w-screen sm:w-screen "
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
            {openModal && (
              <Modal
                okButtonProps={{ className: "bg-rgbaprimary9" }}
                title="Hold Battles Details"
                open={openModal}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <Table
                  columns={columnsModal}
                  dataSource={dataMore}
                  pagination={false}
                  // bordered
                  size="small"
                  rowClassName="table-row"
                />
              </Modal>
            )}
          </>
        )}
      </>
    </div>
  );
}
export default HoldBattles;
