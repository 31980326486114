import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  message,
  Tag,
  Switch,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { editSettings, getSettings } from "../../../services/apiFunctions";
import { messageConfiguration } from "../../../utils";

const Settings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const formRef = useRef(form);

  useEffect(() => {
    getSettingsDetails();
  }, []);

  const getSettingsDetails = () => {
    getSettings()
      .then((res) => {
        if (res.data.success) {
          const settingsData = res?.data?.data;
          setData(settingsData);
          settingsData.forEach((data, index) => {
            form.setFieldsValue({
              [`minValue-${index}`]: data?.minValue,
              [`maxValue-${index}`]: data?.maxValue,
              [`name-${index}`]: data?.name,
              [`_id-${index}`]: data?._id,
              [`Value-${index}`]: data?.Value,
              [`publicMessage-${index}`]: data?.publicMessage,
            });
          });
          setIsLoading(false);
        } else {
          message.error("error", res.data.error, 3);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const editSettingsDetails = (changedValues, index) => {
    // const dataObj = {};
    // for (const key in changedValues) {
    //   dataObj[key] = changedValues[key];
    // }

    // // Include maxValue with a fixed value of 0 for "SignUpBonus"
    // if (dataObj[`name-${index}`] === "SignUpBonus") {
    //   dataObj[`maxValue-${index}`] = 0;
    // }
    // const changedValues = form.getFieldsValue();
    // console.log(changedValues, index);
    const payload = {
      key: changedValues[`name-${index}`],
      maxValue: changedValues[`maxValue-${index}`],
      minValue: changedValues[`minValue-${index}`],
    };

    editSettings(payload)
      .then((res) => {
        if (res.data.success) {
          message.open(messageConfiguration("success", res.data?.message, 3));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editToggleDetails = (index) => {
    const changedValues = form.getFieldsValue();
    console.log(changedValues, index);
    const payload = {
      key: changedValues[`name-${index}`],
      Value: changedValues[`Value-${index}`],
      publicMessage: changedValues[`publicMessage-${index}`],
    };

    editSettings(payload)
      .then((res) => {
        if (res.data.success) {
          message.open(messageConfiguration("success", res.data?.message, 3));
          getSettingsDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editUPIDetails = (index) => {
    const changedValues = form.getFieldsValue();
    const payload = {
      key: changedValues[`name-${index}`],
      publicMessage: changedValues[`publicMessage-${index}`],
    };
    editSettings(payload)
      .then((res) => {
        if (res.data.success) {
          message.open(messageConfiguration("success", res.data?.message, 3));
          getSettingsDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <div>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            Settings
          </div>
          {data.length > 0 ? (
            <Form
              className="bg-white p-6"
              layout="vertical"
              onFinishFailed={(err) => console.log(err)}
              form={form}
              ref={formRef}
            >
              {data.slice(0, 5).map((item, index) => (
                <Row gutter={16} key={index}>
                  <Col span={6}>
                    <Form.Item name={`name-${index}`} initialValue={item?.name}>
                      <Tag
                        style={{
                          fontWeight: "bold",
                          color: "green",
                          backgroundColor: "#e0f5f0",
                          borderColor: "lightgreen",
                        }}
                      >
                        {item?.name}
                      </Tag>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={`minValue-${index}`}
                      label={
                        item?.name !== "SignUpBonus" &&
                        item?.name !== "Referral_link_user_Commission" &&
                        item?.name !== "Referral_link_Admin_Commission"
                          ? "Min. Value(₹)"
                          : ""
                      }
                      // label="Min. Value(₹)"
                      initialValue={item?.minValue}
                      rules={[
                        {
                          required: true,
                          message: "Please enter minimum amount",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {item?.name !== "SignUpBonus" &&
                    item?.name !== "Referral_link_user_Commission" &&
                    item?.name !== "Referral_link_Admin_Commission" && (
                      <Col span={6}>
                        <Form.Item
                          name={`maxValue-${index}`}
                          label="Max. Value(₹)"
                          initialValue={item?.maxValue}
                          rules={[
                            {
                              required: true,
                              message: "Please enter maximum amount",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  <Col span={6}>
                    <Form.Item
                      className={
                        item?.name !== "SignUpBonus" &&
                        item?.name !== "Referral_link_user_Commission" &&
                        item?.name !== "Referral_link_Admin_Commission"
                          ? `mt-8`
                          : ""
                      }
                    >
                      <Button
                        shape="round"
                        size="small"
                        onClick={() =>
                          editSettingsDetails(form.getFieldsValue(), index)
                        }
                      >
                        Edit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              {data.slice(5, 7).map((item, index) => (
                <Row gutter={16} key={index}>
                  <Col span={6}>
                    <Form.Item
                      name={`name-${index + 5}`}
                      initialValue={item?.name}
                    >
                      <Tag
                        style={{
                          fontWeight: "bold",
                          color: "green",
                          backgroundColor: "#e0f5f0",
                          borderColor: "lightgreen",
                        }}
                      >
                        {item?.name}
                      </Tag>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={`Value-${index + 5}`}
                      initialValue={item?.Value}
                    >
                      <Switch
                        className="text-center cursor-pointer w-1/2"
                        checkedChildren="True"
                        unCheckedChildren="False"
                        defaultChecked={item?.Value}
                        onChange={() => editToggleDetails(index + 5)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      className="text-xs"
                      name={`publicMessage-${index + 5}`}
                      initialValue={item?.publicMessage}
                      rules={[
                        {
                          required: true,
                          message: "Please enter Public Message",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        shape="round"
                        size="small"
                        onClick={() => editToggleDetails(index + 5)}
                      >
                        Save Message
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              {data.slice(7).map((item, index) => (
                <Row gutter={16} key={index}>
                  <Col span={6}>
                    <Form.Item
                      name={`name-${index + 7}`}
                      initialValue={item?.name}
                    >
                      <Tag
                        style={{
                          fontWeight: "bold",
                          color: "green",
                          backgroundColor: "#e0f5f0",
                          borderColor: "lightgreen",
                        }}
                      >
                        {item?.name}
                      </Tag>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="text-xs"
                      name={`publicMessage-${index + 7}`}
                      initialValue={item?.publicMessage}
                      rules={[
                        {
                          required: true,
                          message: "Please enter UPI",
                        },
                      ]}
                    >
                      <Input placeholder="e.g., anything@payu" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Button
                        shape="round"
                        size="small"
                        onClick={() => editUPIDetails(index + 7)}
                      >
                        {item?.name === "ManualPaymentUPI"
                          ? "Save UPI"
                          : "Save Contact"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </Form>
          ) : (
            <p className="flex justify-center bg-white p-5 text-rgbaprimary5 font-semibold">
              No Data
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default Settings;
