import { useEffect, useState } from "react";
import { Divider, Image, Table, Tag } from "antd";
import { Pagination, Spin } from "antd";
import { fund_detail, recharge_detail } from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime } from "../../../utils";
import { useLocation } from "react-router-dom";

const FundHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const location = useLocation(true);
  const Details = location.state?.Fill;

  function FundHistory() {
    setIsLoading(true);
    fund_detail(`?page=${currentPage}&limit=10`)
      .then((response) => {
        setData(response.data.data);
        setcurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    FundHistory(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_id",
      key: "user_id",
      render: (data, record) => {
        return (
          <Tag
            className="text-xs flex w-1/2 justify-center bg-rgbaprimary2 border-rgbaprimary5"
            title={record?.user_id?._id}
          >
            {" "}
            {record?.user_id?.name}
          </Tag>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (data, record) => {
        return <p> {record?.user_id?.mobile}</p>;
      },
    },
    {
      title: " Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (data, record) => {
        return record?.image === null ? (
          <p>No Image</p>
        ) : (
          <Image
            width={50}
            height={50}
            src={`${process.env.REACT_APP_IMAGE_URL}${record?.image}`}
            // className="bg-rgbaprimary9 text-white rounded-md justify-center text-center  cursor-pointer"
            // onClick={() => {
            //   navigate("/customers/viewDetail", {
            //     state: {
            //       Fill: record,
            //     },
            //   });
            // }}
          />
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (data, record) => {
        return record.remarks ? (
          <Tag className="text-xs flex w-1/2 justify-center bg-rgbaprimary2 border-rgbaprimary5">
            {record?.remarks}
          </Tag>
        ) : (
          <Tag className="text-xs flex w-1/2 justify-center bg-rgbaprimary2 border-rgbaprimary5">
            ---
          </Tag>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => (
        <p className="  rounded-md justify-center text-center w-1/2 cursor-pointer">
          {ExtractDate(record.createdAt)}•{ExtractTime(record.createdAt)}
        </p>
      ),
      
    },
  ];
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            Fund History
          </div>

          <div>
            <Divider
              orientation="center"
              className="fs-4  theme-darkt fw-semi-bold"
            >
              Fund History
            </Divider>
          </div>
          <div
            style={{ maxHeight: "500px", overflow: "auto" }}
            className="bg-white"
          >
            <Table
              bordered={true}
              columns={columns}
              dataSource={data}
              pagination={false}
              className="w-screen sm:w-screen "
            />
            <Pagination
              className="mt-2  w-full "
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * totalPage}
              showSizeChanger={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FundHistory;
