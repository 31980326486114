import { useEffect, useState } from "react";
import { Divider, Switch, Table, Tag } from "antd";
import { Pagination, Spin } from "antd";
import {
  battle_detail,
  updatePaymentStatus,
} from "../../../services/apiFunctions";
import { ExtractDate } from "../../../utils";
const BattleHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  function BattleTable() {
    setIsLoading(true);
    battle_detail()
      .then((response) => {
        setData(response.data.data);
        setcurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  function changePaymentStatus(checked, record) {
    let payload = {
      payment_status: checked ? 1 : 0,
    };
    updatePaymentStatus(payload, record.id)
      .then((response) => {
        if (response.data.status) {
          const updatedPaymentTable = data.map((item) =>
            item.id === record.id
              ? { ...item, payment_status: checked ? 1 : 0 }
              : item
          );
          setData(updatedPaymentTable);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    BattleTable(currentPage);
  }, [currentPage]);

  //   const handlePageChange = (page, pageSize) => {
  //     setcurrentPage(page);
  //   };

  const columns = [
    {
      title: " Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "LOAN ID",
      dataIndex: "loanId",
      key: "loanId",
    },
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: " Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      render: (data, record) => {
        return record.payment_status === 1 ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Paid
          </Tag>
        ) : (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Pending
          </Tag>
        );
      },
    },

    {
      title: "Payment Due Date",
      dataIndex: "payment_due_date",
      key: "payment_due_date",
      render: (data, record) => (
        <p className="  rounded-md justify-center text-center w-1/2 cursor-pointer">
          {ExtractDate(record.payment_due_date)}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data, record) => {
        return record.payment_status === 0 ? (
          <Switch
            className="text-center cursor-pointer"
            checkedChildren="Paid"
            unCheckedChildren="Unpaid"
            onChange={(checked) => changePaymentStatus(checked, record)}
          />
        ) : (
          <Switch
            disabled
            checkedChildren="Paid"
            unCheckedChildren="Unpaid"
            defaultChecked
          />
        );
      },
    },
  ];
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
        Battle History
          </div>

          <div>
            <Divider
              orientation="center"
              className="fs-4  theme-darkt fw-semi-bold"
            >
              Battle History
            </Divider>
          </div>
          <div
            style={{ maxHeight: "500px", overflow: "auto" }}
            className="bg-white"
          >
            <Table
              bordered={true}
              columns={columns}
              dataSource={data}
              pagination={false}
              className="w-screen sm:w-screen "
            />
            {/* <Pagination
              className="mt-2  w-full "
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * totalPage}
              showSizeChanger={false}
            /> */}
          </div>
        </>
      )}
    </>
  );
};

export default BattleHistory;
