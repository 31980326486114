import React, { useRef, useEffect } from "react";
import {
  message,
  Button,
  Form,
  Input,
  Modal,
  Col,
  Row,
  Select,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { tournament_edit } from "../../../services/apiFunctions";

const EditTournament = ({ isOpen, setIsOpen, apiCall, Fill }) => {
  const [form] = Form.useForm();
  const formRef = useRef(form);

  useEffect(() => {
    form.setFieldsValue({
      min_entry_fee: Fill.min_entry_fee,
      max_entry_fee: Fill.max_entry_fee,
      name: Fill.name,
      // commission_fee_percentage: Fill.commission_fee_percentage,
      commission_rates: Fill.commission_rates,
      is_live: Fill.is_live,
    });
  }, [Fill, form]);

  const EditTournament = (changedValues) => {
    const payload = {
      min_entry_fee: changedValues.min_entry_fee,
      max_entry_fee: changedValues.max_entry_fee,
      name: changedValues.name,
      // commission_fee_percentage: changedValues.commission_fee_percentage,
      commission_rates: changedValues.commission_rates,
      is_live: true,
    };
    tournament_edit(payload, Fill._id)
      .then((response) => {
        if (response.data.success) {
          message.success({ content: "Tournament Edited Successfully" });

          apiCall();
          setIsOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        EditTournament(values);
        setIsOpen(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        footer={null}
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Edit Tournament"
      >
        <Form
          className="bg-white p-1"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={EditTournament}
          form={form}
          ref={formRef}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label="Name">
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="commission_fee_percentage"
                label="Commission Fee Percentage(%)"
                rules={[
                  {
                    required: true,
                    message: "Please enter Commission Fee Percentage",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Amount must only contain numbers",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="min_entry_fee"
                label="Minimum Entry Fee"
                rules={[
                  {
                    required: true,
                    message: "Please enter Minimum Entry Fee",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: " Entry Fee must only contain numbers",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="max_entry_fee"
                label="Maximum Entry Fee"
                rules={[
                  {
                    required: true,
                    message: "Please enter Maximum Entry Fee",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Maximum Entry Fee must only contain numbers",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="commission_rates">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row gutter={16} key={key} align="middle">
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "min_entry_fee"]}
                        fieldKey={[fieldKey, "min_entry_fee"]}
                        label="Min Entry Fee"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Min Entry Fee",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message: "Min Entry Fee must only contain numbers",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "max_entry_fee"]}
                        fieldKey={[fieldKey, "max_entry_fee"]}
                        label="Max Entry Fee"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Max Entry Fee",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message: "Max Entry Fee must only contain numbers",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "raferrer_commission_percentage"]}
                        fieldKey={[fieldKey, "raferrer_commission_percentage"]}
                        label="Raferrer Commission (%)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Raferrer Commission (%)",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message:
                              "Raferrer Commission (%) must only contain numbers",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "admin_commission_percentage"]}
                        fieldKey={[fieldKey, "admin_commission_percentage"]}
                        label="Admin Commission (%)"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Admin Commission (%)",
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message:
                              "Admin Commission (%) must only contain numbers",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, "commission_fee_percentage"]}
                          fieldKey={[fieldKey, "commission_fee_percentage"]}
                          label="Commission Fee Percentage(%)"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Commission Fee Percentage",
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message: "Amount must only contain numbers",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                   
                    <Col span={2}>
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{ margin: "0 8px" }}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item className="flex justify-center mt-4">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Commission Rate
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div>
            <Form.Item className="flex justify-center mt-4">
              <Button
                type="default"
                htmlType="submit"
                className="bg-primary text-white"
              >
                Edit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EditTournament;
