import { useEffect, useState } from "react";
import { Divider, Input, Table, Tag } from "antd";
import { Pagination, Spin } from "antd";
import {
  getSearchRefferalPenalty,
  refferalPenalty_detail,
} from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime } from "../../../utils";

const RefferalPenalty = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [currentSearchPage, setCurrentSearchPage] = useState(1);
  const [totalPage, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const { Search } = Input;
  function RefferalPenaltyDetail() {
    setIsLoading(true);
    refferalPenalty_detail(`?page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setData(response.data.data);
          setcurrentPage(response.data.Pagination.page);
          setTotalPages(response.data.Pagination.totalPage);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    RefferalPenaltyDetail(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    if (searchTerm === "") {
      setcurrentPage(page);
    } else {
      setCurrentSearchPage(page);
    }
  };

  useEffect(() => {
    if (searchTerm === "") {
      RefferalPenaltyDetail(currentPage);
    } else {
      getSearchRefferalPenalty(
        `${searchTerm}&pagesize=${10}&page=${currentSearchPage}`
      )
        .then((response) => {
          if (response.data?.status) {
            setData(response?.data?.data);
            setCurrentSearchPage(response.data?.Pagination?.page);
            setTotalPages(response.data?.Pagination?.totalPage);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm, currentPage,currentSearchPage]);

  const columns = [
    {
      title: " User",
      dataIndex: "name",
      key: "name",
      render: (data, record) => (
        <Tag
          className="bg-rgbaprimary3  rounded-md justify-center text-center cursor-pointer"
          title={record?.user_id?._id}
        >
          {record?.user_id?.name}
        </Tag>
      ),
    },
    {
      title: " Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
      render: (data, record) => (
        <Tag className="bg-rgbaprimary3  rounded-md justify-center text-center cursor-pointer">
          {record?.user_id?.mobile}
        </Tag>
      ),
    },
    {
      title: " Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Amount Type",
      dataIndex: "amount_type",
      key: "amount_type",
      render: (data, record) => {
        return record.amount_type === "cr" ? (
          <Tag className=" bg-rgbaprimary8 text-white rounded-md justify-center text-center ">
            Credit
          </Tag>
        ) : (
          <Tag className=" bg-rgbaprimary8 text-white rounded-md justify-center text-center ">
            Debit
          </Tag>
        );
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (data, record) => {
        return record.transaction_type === "bonus" ? (
          <Tag className=" bg-rgbaprimary6 text-white rounded-md justify-center text-center ">
            {record.transaction_type}
          </Tag>
        ) : (
          <Tag className=" bg-red-300 text-white rounded-md justify-center text-center ">
            {record.transaction_type}
          </Tag>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => (
        <p className="rounded-md justify-center text-center">
          {ExtractDate(record.createdAt)} • {ExtractTime(record.createdAt)}
        </p>
      ),
    },
  ];
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            Refferal Bonus Penalty Details
          </div>
          <div className="flex items-end justify-between">
            <Search
              placeholder="Enter search term..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "25%" }}
            />
          </div>
          <div>
            <Divider
              orientation="center"
              className="fs-4  theme-darkt fw-semi-bold"
            >
              Refferal Bonus Penalty Details
            </Divider>
          </div>
          <div
            style={{ maxHeight: "500px", overflow: "auto" }}
            className="bg-white"
          >
            <Table
              bordered={true}
              columns={columns}
              dataSource={data}
              pagination={false}
              className="w-screen sm:w-screen "
            />
            <Pagination
              className="mt-2  w-full "
              onChange={handlePageChange}
              current={!searchTerm ? currentPage : currentSearchPage}
              defaultCurrent={1}
              total={10 * totalPage}
              showSizeChanger={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RefferalPenalty;
