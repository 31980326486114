import { Divider, Image, Pagination, Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { getwalletDetails } from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime } from "../../../utils";

const WalletDetails = () => {
  const [fundData, setFundData] = useState([]);
  const [defaultAdmin, setdefaultAdminData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  function WalletDetails() {
    getwalletDetails(`?page=${currentPage}&limit=10`)
      .then((response) => {
        if (response.data.status) {
          setdefaultAdminData(response.data?.defaultAdmin);
          setFundData(response.data?.data);
          setCurrentPage(response.data.pagination.page);
          setTotalPages(response.data.pagination.totalPages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    WalletDetails(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      render: (data, record) => {
        return (
          <Tag
            className=" bg-rgbaprimary2 border-rgbaprimary5"
            title={defaultAdmin?._id}
          >
            {defaultAdmin.name}
          </Tag>
        );
      },
    },
    {
      title: "Amount (₹)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Amount Type",
      dataIndex: "amount_type",
      key: "amount_type",
    },
    {
      title: "Balance Before (₹)",
      dataIndex: "balance_before",
      key: "balance_before",
    },
    {
      title: "Balance After (₹)",
      dataIndex: "balance_after",
      key: "balance_after",
    },
    {
      title: "Commision Id",
      dataIndex: "commision_id",
      key: "commision_id",
      render: (data, record) => {
        return record.commision_id === null ? (
          <p className="flex justify-center">_____</p>
        ) : (
          <p className="text-xs">{record.commision_id}</p>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => {
        return <p className="text-xs">{ExtractDate(record.createdAt)}•{ExtractTime(record.createdAt)}</p>;
      },
    },
    {
      title: "Withdrawable Status",
      dataIndex: "is_withdrawable",
      key: "is_withdrawable",
      render: (data, record) => {
        return record.is_withdrawable === true ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Withdrawable
          </Tag>
        ) : (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Not Withdrawable
          </Tag>
        );
      },
    },
    {
      title: "Transaction type",
      dataIndex: "transaction_type",
      key: "transaction_type",
    },
  ];

  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Wallet Requests
            </div>

            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                Wallet Details
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                bordered={true}
                columns={columns}
                dataSource={fundData}
                pagination={false}
                className="w-screen sm:w-screen "
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};
export default WalletDetails;
