import { Spin } from "antd";
import React, { Suspense } from "react";
import { HashRouter as Router, useRoutes } from "react-router-dom";
import publicRoutes from "./Routes/PublicRoutes";
import privateAdminRoute from "./Routes/PrivateRoutes";
import "./App.css";
function App() {
  const userRouting = [...publicRoutes(), ...privateAdminRoute()];
  const UserRouteConfig = () => useRoutes(userRouting);

  return (
    // <AuthProvider>
    <Router>
      <Suspense
        fallback={
          <div className="Spinner">
            <Spin size="default" />
            <p>Loading Page...</p>
          </div>
        }
      >
        <UserRouteConfig />
      </Suspense>
    </Router>
    // </AuthProvider>
  );
}

export default App;

