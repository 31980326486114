import React, { useRef } from "react";
import { message, Button, Form, Input, Modal } from "antd";
import { addrule, rule_edit } from "../../../services/apiFunctions";

const AddEditRules = ({ isOpen, setIsOpen, apiCall, from, Fill }) => {
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const { TextArea } = Input;
  const createRule = (changedValues) => {
    const payload = {
      description: changedValues.rule,
    };
    addrule(payload)
      .then((response) => {
        if (response.data.success) {
          message.success({ content: response.data.message });

          apiCall();
          setIsOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const editRule = (changedValues) => {
    const payload = {
      description: changedValues.rule,
      ruleId: Fill._id,
    };
    rule_edit(payload)
      .then((response) => {
        if (response.data.success) {
          message.success({ content: "News Edited Successfully" });

          apiCall();
          setIsOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        createRule(values);
        setIsOpen(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        footer={null}
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={`${from === "add" ? "Add" : "Edit"} Game Rule`}
      >
        <Form
          className="bg-white p-6"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={from === "add" ? createRule : editRule}
          form={form}
          ref={formRef}
          initialValues={
            from === "add"
              ? {}
              : {
                  rule: Fill.rule_Description,
                }
          }
        >
          <Form.Item
            name="rule"
            label=" Game Rule"
            rules={[
              {
                required: true,
                message: "Please enter rule",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.split(/\s+/).length <= 250) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Rule text limit is 250 words")
                  );
                },
              }),
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <div>
            <Form.Item>
              <Button type="primary" htmlType="onSubmit">
                {from === "edit" ? "Edit" : "Add "}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditRules;
