import React, { useEffect, useState } from "react";
import {
  Spin,
  Pagination,
  Table,
  Divider,
  Tag,
  Image,
  message,
  Input,
  Button,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import {
  fetchUser,
  getSearchuser,
  modifyStatus,
  putWalletAction,
  putremoveRefree,
} from "../../../services/apiFunctions";
import { messageConfiguration } from "../../../utils";
import { useNavigate } from "react-router-dom";

function Users() {
  const [action, setAction] = useState("");
  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState("");
  const [cashType, setCashType] = useState("");
  const [actionConfirmed, setActionConfirmed] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [from, setFrom] = useState("add");
  const [userList, setUserList] = useState([]);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageCount, setPageCount] = useState();
  const { Search } = Input;
  const navigate = useNavigate();
  const setBattleAction = (id, value) => {
    setAction((prevAction) => ({
      ...prevAction,
      [id]: value,
    }));
  };
  function customerDetailTable(currentPage) {
    fetchUser(`?page=${currentPage}&limit=10`)
      .then((response) => {
        setData(response.data.data);
        setCurrentPage(response.data.page);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function wallet_actions(checked, record) {
    const payload = {
      userId: record._id,
      amount: amount,
      remark: remark,
      cashType: cashType,
    };
    putWalletAction(payload, checked)
      .then((response) => {
        if (response.data.Status) {
          message.open(
            messageConfiguration("success", response.data.message, 3)
          );
          customerDetailTable();
          setCashType("");
          // BattleIwinRequestsDetails();
          setActionConfirmed(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function remoeRefree(record) {
    putremoveRefree(record._id)
      .then((response) => {
        if (response.data.status) {
          customerDetailTable();
          message.open(
            messageConfiguration("success", "Referee Removed Successfully", 3)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const cancel = (e) => {
    console.log(e);
  };

  function ModifyUserStatus(checked, record) {
    console.log(checked, record);
    modifyStatus(`${record._id}`)
      .then((response) => {
        if (response.status) {
          customerDetailTable();
          
          message.open(
            messageConfiguration("success", response.data.message, 1)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, record) => {
        return record?.image === null ? (
          <>
            <Tag>No Image</Tag>
            <Tag className=" bg-rgbaprimary2 border-rgbaprimary5">
              {record?.name?.toUpperCase() ?? "--"}
            </Tag>
          </>
        ) : (
          <>
            <Image
              width={50}
              height={50}
              src={`${process.env.REACT_APP_IMAGE_URL}${record?.image}`}
              // className="bg-rgbaprimary9 text-white rounded-md justify-center text-center  cursor-pointer"
              // onClick={() => {
              //   navigate("/customers/viewDetail", {
              //     state: {
              //       Fill: record,
              //     },
              //   });
              // }}
            />
            <Tag className=" bg-rgbaprimary2 border-rgbaprimary5">
              {record?.name?.toUpperCase() ?? "--"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      render: (data, record) => {
        return <p>{record?.email ?? "--"}</p>;
      },
    },
    {
      title: "Aadhar Number",
      dataIndex: "aadhar_no",
      key: "aadhar_no",
      render: (data, record) => {
        return <p>{record?.aadhar_no ?? "--"}</p>;
      },
    },
    {
      title: "Aadhar Image Front",
      dataIndex: "aadhar_image_front",
      key: "aadhar_image_front",
      render: (data, record) => {
        return record?.aadhar_image_front === null ? (
          <p>No Image</p>
        ) : (
          <Image
            width={50}
            height={50}
            src={`${process.env.REACT_APP_IMAGE_URL}${record?.aadhar_image_front}`}
          />
        );
      },
    },
    {
      title: "Aadhar Image Back",
      dataIndex: "aadhar_image_back",
      key: "aadhar_image_back",
      render: (data, record) => {
        return record?.aadhar_image_back === null ? (
          <p>No Image</p>
        ) : (
          <Image
            width={50}
            height={50}
            src={`${process.env.REACT_APP_IMAGE_URL}${record?.aadhar_image_back}`}
          />
        );
      },
    },
    {
      title: "Total wallet balance",
      dataIndex: "wallet_balance",
      key: "wallet_balance",
      render: (data, record) => (
        <p>{record?.wallet_balance.toFixed(2) ?? "--"} ₹</p>
      ),
    },
    {
      title: "Recharged Balance",
      dataIndex: "Current_withdable_amount",
      key: "Current_withdable_amount",
      render: (data, record) => {
        const walletBalance = record?.wallet_balance ?? 0;
        const currentWithdrawableAmount = record?.Current_withdable_amount ?? 0;
        const result = (walletBalance - currentWithdrawableAmount).toFixed(2);

        return <p>{result} ₹</p>;
      },
    },
    {
      title: "Winning Balance",
      dataIndex: "Current_withdable_amount",
      key: "Current_withdable_amount",
      render: (data, record) => (
        <p>{record?.Current_withdable_amount.toFixed(2) ?? "--"} ₹</p>
      ),
    },
    {
      title: "Wallet Action",
      dataIndex: "wallet_action",
      key: "wallet_action",
      render: (data, record) => {
        return (
          <div>
            <Select
              // disabled={record.request_status !== "under_process"}
              placeholder={record?.request_status}
              style={{
                width: 120,
              }}
              onChange={(value) => setBattleAction(record?._id, value)}
              value={action[record?._id]}
            >
              <Select.Option value="bonus">Bonus</Select.Option>
              <Select.Option value="penalty">Penalty</Select.Option>
            </Select>
            {action[record?._id] === "bonus" && (
              <Popconfirm
                title={
                  <div>
                    <input
                      type="text"
                      value={remark[record?._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      className="mb-5 border-dotted"
                    />
                    <br />
                    <input
                      type="number"
                      value={amount[record?._id]}
                      onChange={(e) => setAmount(parseFloat(e.target.value))}
                      placeholder="Enter amount"
                      className="mb-5"
                    />
                    <br />
                    <Select
                      placeholder="Select Cash type"
                      style={{
                        width: 120,
                      }}
                      onChange={(value) => setCashType(value)}
                      value={cashType}
                    >
                      <Select.Option value="winCash">Win Cash</Select.Option>
                      <Select.Option value="depCash">
                        Deposit Cash
                      </Select.Option>
                    </Select>
                  </div>
                }
                onConfirm={() => wallet_actions("bonus", record)}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button type="link">Enter Remark</Button>
              </Popconfirm>
            )}

            {action[record._id] === "penalty" && (
              <Popconfirm
                title={
                  <>
                    <input
                      type="text"
                      value={remark[record?._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      className="mb-5"
                    />
                    <br />
                    <input
                      type="text"
                      value={amount[record?._id]}
                      onChange={(e) => setAmount(parseFloat(e.target.value))}
                      placeholder="Enter amount"
                      className="mb-5"
                    />
                    <br />
                    <Select
                      placeholder="Select Cash type"
                      style={{
                        width: 120,
                      }}
                      onChange={(value) => setCashType(value)}
                      value={cashType}
                    >
                      <Select.Option value="winCash">Win Cash</Select.Option>
                      <Select.Option value="depCash">
                        Deposit Cash
                      </Select.Option>
                    </Select>
                  </>
                }
                onConfirm={() => wallet_actions("penalty", record)}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button type="link">Enter Remark</Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "Verification Status",
      dataIndex: "isVerified",
      key: "isVerified",
      render: (data, record) => {
        return record?.isVerified === true ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Verified
          </Tag>
        ) : (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Not Verified
          </Tag>
        );
      },
    },
    {
      title: "Recharge History",
      dataIndex: "history",
      key: "history",
      render: (data, record) => (
        <Button
          className=" bg-rgbaprimary3 rounded-md justify-center text-center  cursor-pointer"
          onClick={() => {
            console.log("Cursor", record);
            navigate("/rechargeHistory", {
              state: {
                Fill: record,
              },
            });
            // setFilldata(record);
            // setOpenModal(true);
          }}
        >
          Fund History
        </Button>
      ),
    },
    {
      title: "Status",
      key: "isActiveUser",
      dataIndex: "isActiveUser",
      render: (data, record) => {
        return (
          <Switch
            defaultChecked={record.isActiveUser}
            checkedChildren="Block "
            unCheckedChildren="Unblock"
            onChange={(checked) => ModifyUserStatus(checked, record)}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "view",
      key: "view",
      render: (data, record) => {
        return record?.referred_by === null ? (
          <Button disabled>Refree Removed</Button>
        ) : (
          //     <Popconfirm
          //     title="Remove Refree"
          //     description="Are you sure to remove this refree?"
          //     onConfirm={() => remoeRefree(record)}
          //     onCancel={cancel}
          //     okText="Yes"
          //     cancelText="No"
          //     className="bg-rgbaprimary9 text-white rounded-md justify-center text-center  cursor-pointer"
          //   >
          //  <p> Remove Refree</p>
          //   </Popconfirm>
          <Button
            className="bg-rgbaprimary9 text-white rounded-md justify-center text-center  cursor-pointer"
            onClick={() => remoeRefree(record)}
          >
            Remove Refree
          </Button>
        );
      },
    },
  ];
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    customerDetailTable(currentPage);
  }, [currentPage]);

  console.log(currentPage);
  useEffect(() => {
    if (searchTerm === "") {
      customerDetailTable(currentPage);
    } else {
      getSearchuser(`${searchTerm}&pagesize=${10}`)
        .then((response) => {
          if (response.status) {
            console.log(response);
            setData(response.data?.data);
            // const mailData = response.data.data.map((item) => item.email_id)
            // dispatch(setContactMail(mailData))
            // setShowSkeleton(false)
            setTotalPages(response.data?.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm, currentPage]);
  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              User
            </div>
            <div className="flex items-end justify-between">
              <Search
                placeholder="search users here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
            </div>

            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                User Details
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="w-screen sm:w-screen "
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>

            {/* {openModal && (
              <AddCustomer
                isOpen={openModal}
                setIsOpen={setOpenModal}
                Fill={filldata}
                setFill={setFilldata}
                from={from}
                apiCall={customerDetailTable}
              />
            )} */}
          </>
        )}
      </>
    </div>
  );
}
export default Users;
