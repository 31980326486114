import React, { useRef, useState } from "react";
import { Upload, message, Button, Form, Input, Row, Col, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { addFund } from "../../../services/apiFunctions";
import { MdUpload } from "react-icons/md";

const AddFund = ({ isOpen, setIsOpen, from, apiCall }) => {
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const [imageAdhar, setImageAdhar] = useState(null);
  const MAX_FILE_SIZE_MB = 25;

  const createFund = (changedValues) => {
    if (from === "add" && imageAdhar) {
      let formData = new FormData();
      formData.append("image", imageAdhar);
      formData.append("amount", changedValues.amount);

      addFund(formData)
        .then((response) => {
          if (response.data.status) {
            message.success({ content: response.data.message });
            apiCall();
            setIsOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        createFund(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const props = {
    name: "file",
    accept: ".jpg,.jpeg,.png,.pdf",
    beforeUpload: (file) => {
      const isLt25M = file.size / 1024 / 1024 < MAX_FILE_SIZE_MB;
      if (!isLt25M) {
        message.error(`File must be smaller than ${MAX_FILE_SIZE_MB}MB!`);
        return false;
      }
      setImageAdhar(file);
      return false; // Prevent upload
    },
  };

  return (
    <>
      <Modal
        footer={null}
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={`${from === "add" ? "Add" : "Edit"} Fund`}
      >
        <Form
          className="bg-white p-6"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={createFund}
          form={form}
          ref={formRef}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter Amount",
                  },
                  {
                    message: "Please enter a valid Amount",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="upload" label="Add attachments">
                <Upload {...props}>
                  <Button icon={<MdUpload />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Form.Item className="flex justify-center mt-4">
              <Button type="primary" htmlType="onSubmit">
                {from === "edit" ? "Edit" : "Create"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddFund;
