import { Card, Spin, message, Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  deleteNews,
  deleteRule,
  getFundNews,
  getgameRules,
} from "../../../services/apiFunctions";
import { MdDelete, MdEdit } from "react-icons/md";
import { messageConfiguration } from "../../../utils";
import AddEditRules from "./AddEditRules";

const GameRules = () => {
  const [gameRules, setGameRules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState({});
  const [openRulesModal, setOpenRulesModal] = useState(false);
  const [currentRulePage, setCurrentRulePage] = useState(1);
  const [from, setFrom] = useState("add");

  useEffect(() => {
    GameRules(currentRulePage);
  }, [currentRulePage]);

  const GameRules = () => {
    getgameRules()
      .then((response) => {
        if (response.data.status) {
          setGameRules(response.data?.data);
          setCurrentRulePage(response.data?.pagination?.page);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const DeleteRule = (id) => {
    deleteRule(id)
      .then((response) => {
        if (response.data.success) {
          message.open(
            messageConfiguration("success", response.data.message, 3)
          );
          GameRules();
        }
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const renderRuleCard = (
    title,
    RuleList,
    setOpenModalFunction,
    setFillFunction,
    setFromFunction
  ) => (
    <div style={{ width: "100%", marginBottom: "20px" }}>
      <Card title={title}>
        {RuleList.length === 0 ? (
          <p>No data</p>
        ) : (
          RuleList.map((i, index) => (
            <div className="flex items-center justify-between my-2" key={index}>
              <p className="text-gray-500 mb-2">✦ {i.rule_Description}</p>
              <div className="flex items-center">
                <MdEdit
                  title="Edit"
                  className="cursor-pointer text-lg text-gray-300 mr-2"
                  onClick={() => {
                    setOpenModalFunction(true);
                    setFromFunction("edit");
                    setFillFunction(i);
                  }}
                />
                <MdDelete
                  title="Delete"
                  className="cursor-pointer text-lg text-red-300"
                  onClick={() => {
                    DeleteRule(i._id);
                  }}
                />
              </div>
            </div>
          ))
        )}
        <Button
          // type="primary"
          className="bg-primary text-white"
          onClick={() => {
            setOpenModalFunction(true);
            setFromFunction("add");
          }}
        >
          Add Rule
        </Button>
      </Card>
    </div>
  );

  return (
    <div>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            Game Rules
          </div>

          {renderRuleCard(
            "Rules Display",
            gameRules,
            setOpenRulesModal,
            setFilldata,
            setFrom
          )}

          {openRulesModal && (
            <AddEditRules
              isOpen={openRulesModal}
              setIsOpen={setOpenRulesModal}
              Fill={filldata}
              setFill={setFilldata}
                apiCall={GameRules}
              from={from}
            />
          )}
        </>
      )}
    </div>
  );
};

export default GameRules;
