import React, { useEffect, useState } from "react";
import {
  Spin,
  Pagination,
  Table,
  Divider,
  Tag,
  Image,
  Switch,
  Select,
  Popconfirm,
  Button,
  Input,
} from "antd";
import {
  getKycRequest,
  getSearchKyc,
  updateKYCStatus,
} from "../../../services/apiFunctions";
import { useNavigate } from "react-router-dom";
import { ExtractDate } from "../../../utils";
import moment from "moment";

function KycDetails() {
  const [openModal, setOpenModal] = useState(false);
  const [from, setFrom] = useState("add");
  const [kycdata, setKycData] = useState([]);
  const [totalPage, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [action, setAction] = useState({});
  const [remark, setRemark] = useState("");
  const [actionConfirmed, setActionConfirmed] = useState(false);
  const navigate = useNavigate();
  const { Search } = Input;
  function getKYcDetail(currentPage) {
    getKycRequest(`?page=${currentPage}&limit=10`)
      .then((response) => {
        setData(response.data.data);
        setCurrentPage(response.data.pagination.page);
        setTotalPages(response.data.pagination.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  const setKycAction = (id, value) => {
    setAction((prevAction) => ({
      ...prevAction,
      [id]: value,
    }));
  };
  function changeKYCStatus(action, record) {
    const payload = {
      remark: remark,
    };
    updateKYCStatus(payload, `${record._id}?action=${action}`)
      .then((response) => {
        if (response.data.status) {
          const updatedKYCTable = kycdata.map((item) =>
            item._id === record._id
              ? { ...item, kyc_status: action, remark: remark }
              : item
          );
          setKycData(updatedKYCTable);
          getKYcDetail();
          // setActionConfirmed(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    if (searchTerm === "") {
      getKYcDetail(currentPage);
    } else {
      getSearchKyc(`${searchTerm}&pagesize=${10}`)
        .then((response) => {
          if (response.data.status) {
            console.log(response);
            setData(response.data?.data);
            // const mailData = response.data.data.map((item) => item.email_id)
            // dispatch(setContactMail(mailData))
            // setShowSkeleton(false)
            setTotalPages(response.data?.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm, currentPage]);
  const columns = [
    {
      title: "User Name",
      dataIndex: "user_id",
      key: "user_id",
      render: (data, record) => {
        return (
          <Tag
          className=" bg-rgbaprimary2 border-rgbaprimary5"
            title={record?.user_id?._id}
          >
            {" "}
            {record?.user_id?.name}
          </Tag>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (data, record) => {
        return <p> {record?.user_id?.mobile}</p>;
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) =>{
        const getDateDifference = (createdAt) => {
          const currentDate = moment();
          const createdDate = moment(createdAt);
          const diffInDays = currentDate.diff(createdDate, "days");
          return diffInDays;
        };
        return(
<p>  {getDateDifference(record.createdAt)} days ago</p>
        )
      } ,
    },
   
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
    },
    
    {
      title: "Aadhar Number",
      dataIndex: "aadhar_number",
      key: "aadhar_number",
    },
    {
      title: "Aadhar Front Image",
      dataIndex: "aadhar_front_image",
      key: "aadhar_front_image",
      render: (data, record) => (
        <Image
          width={50}
          height={50}
          src={`${process.env.REACT_APP_IMAGE_URL}${record.aadhar_front_image}`}
        />
      ),
    },
    {
      title: "Aadhar Back Image",
      dataIndex: "aadhar_back_image",
      key: "aadhar_back_image",
      render: (data, record) => (
        <Image
          width={50}
          height={50}
          src={`${process.env.REACT_APP_IMAGE_URL}${record.aadhar_back_image}`}
        />
      ),
    },
    {
      title: "KYC Status",
      dataIndex: "kyc_status",
      key: "kyc_status",
      render: (data, record) => {
        return record?.kyc_status === "Approved" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "green",
              backgroundColor: "#e0f5f0",
              borderColor: "lightgreen",
            }}
          >
            Approved
          </Tag>
        ) : record?.kyc_status === "Rejected" ? (
          <Tag
            style={{
              fontWeight: "bold",
              color: "red",
              backgroundColor: "#FFE1E1",
              borderColor: "pink",
            }}
          >
            Rejected
          </Tag>
        ) : (
          <Tag>Pending</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (data, record) => {
        const isDisabled =
          record.kyc_status !== "Pending" &&
          (action[record._id] === "approved" ||
            action[record._id] === "rejected");
        return (
          <div>
            <Select
              disabled={record.kyc_status !== "Pending"}
              placeholder={record.kyc_status}
              style={{
                width: 120,
              }}
              onChange={(value) => setKycAction(record._id, value)}
              value={action[record._id]}
            >
              <Select.Option value="approved">Approved</Select.Option>
              <Select.Option value="rejected">Rejected</Select.Option>
            </Select>
            {action[record._id] === "rejected" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Enter Remark
                    </p>
                    <input
                      type="text"
                      value={remark[record._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                }
                onConfirm={() => {
                  changeKYCStatus("Rejected", record);
                  setActionConfirmed(true);
                }}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button  type="link">
                  Enter Remark
                </Button>
              </Popconfirm>
            )}
            {action[record._id] === "approved" && (
              <Popconfirm
                title={
                  <div>
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Enter Remark
                    </p>
                    <input
                      type="text"
                      value={remark[record._id]}
                      onChange={(e) => setRemark(e.target.value)}
                      placeholder="Enter remark"
                      style={{
                        width: "100%",
                        padding: "8px",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                }
                onConfirm={() => {
                  changeKYCStatus("Approved", record);
                  setActionConfirmed(true);
                }}
                okText="Submit"
                cancelText="Cancel"
              >
                <Button  type="link">
                  Enter Remark
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    getKYcDetail(currentPage);
  }, [currentPage]);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              KYC Details
            </div>
            <div className="flex items-end justify-between">
              <Search
                placeholder="enter mobile number or adhar here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "25%" }}
              />
            </div>

            <div>
              <Divider
                orientation="center"
                className="fs-4  theme-darkt fw-semi-bold"
              >
                KYC Details
              </Divider>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
              className="bg-white"
            >
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="w-screen sm:w-screen "
                size="small"
              />
              <Pagination
                className="mt-2  w-full "
                onChange={handlePageChange}
                current={currentPage}
                defaultCurrent={1}
                total={10 * totalPage}
                showSizeChanger={false}
              />
            </div>

            {/* {openModal && (
              <AddCustomer
                isOpen={openModal}
                setIsOpen={setOpenModal}
                Fill={filldata}
                setFill={setFilldata}
                from={from}
                apiCall={customerDetailTable}
              />
            )} */}
          </>
        )}
      </>
    </div>
  );
}
export default KycDetails;
