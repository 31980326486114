import {
  Delete,
  Get,
  Patch,
  Post,
  getAPIUrl,
  PostFormData,
  Put,
} from "./apiMethod";
import { endpoint } from "./global";

export const loginApi = (data, param = "") => {
  const url = getAPIUrl(endpoint.loginApi, param);
  return Post(url, data, false);
};
export const logout = (data, param = "") => {
  const url = getAPIUrl(endpoint.logout, param);
  return Post(url, data);
};
export const dashboard_count = (param = "") => {
  const url = getAPIUrl(endpoint.dashboard_count, param);
  return Get(url);
};
export const fetchUser = (param = "") => {
  const url = getAPIUrl(endpoint.fetchUser, param);
  return Get(url);
};
export const getSearchuser = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchuser, param);
  return Get(url);
};
export const modifyStatus = (param = "") => {
  const url = getAPIUrl(endpoint.modifyStatus, param);
  return Get(url);
};
export const putremoveRefree = (param = "") => {
  const url = getAPIUrl(endpoint.putremoveRefree, param);
  return Put(url);
};

export const putWalletAction = (data, param = "") => {
  const url = getAPIUrl(endpoint.putWalletAction, param);
  return Post(url, data);
};
export const getKycRequest = (param = "") => {
  const url = getAPIUrl(endpoint.getKycRequest, param);
  return Get(url);
};
export const getSearchKyc = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchKyc, param);
  return Get(url);
};
export const getSearchIwin = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchIwin, param);
  return Get(url);
};
export const getSearchCancel = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchCancel, param);
  return Get(url);
};
export const updateActionStatus = (data, param = "") => {
  const url = getAPIUrl(endpoint.updateActionStatus, param);
  return Put(url, data);
};
export const updateKYCStatus = (data, param = "") => {
  const url = getAPIUrl(endpoint.updateKYCStatus, param);
  return Put(url, data);
};

export const getFundDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getFundDetails, param);
  return Get(url);
};
export const fund_detail = (param = "") => {
  const url = getAPIUrl(endpoint.fund_detail, param);
  return Get(url);
};

export const rechargedetail = (param = "") => {
  const url = getAPIUrl(endpoint.rechargedetail, param);
  return Get(url);
};

export const updateFundStatus = (data, param = "") => {
  const url = getAPIUrl(endpoint.updateFundStatus, param);
  return Put(url, data);
};

export const getTournamentNews = (param = "") => {
  const url = getAPIUrl(endpoint.getTournamentNews, param);
  return Get(url);
};
export const getGeneralNews = (param = "") => {
  const url = getAPIUrl(endpoint.getGeneralNews, param);
  return Get(url);
};
export const getWithdrawalNews = (param = "") => {
  const url = getAPIUrl(endpoint.getWithdrawalNews, param);
  return Get(url);
};
export const getFundNews = (param = "") => {
  const url = getAPIUrl(endpoint.getFundNews, param);
  return Get(url);
};
export const deleteNews = (param = "") => {
  const url = getAPIUrl(endpoint.deleteNews, param);
  return Delete(url, param);
};
export const news_edit = (data, param = "") => {
  const url = getAPIUrl(endpoint.news_edit, param);
  return Put(url, data);
};
export const tournament_edit = (data, param = "") => {
  const url = getAPIUrl(endpoint.tournament_edit, param);
  return Put(url, data);
};
export const getwithdrawDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getwithdrawDetails, param);
  return Get(url);
};
export const getSearchWithdrawRequests = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchWithdrawRequests, param);
  return Get(url);
};
export const getbattleDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getbattleDetails, param);
  return Get(url);
};
export const updateWithdrawStatus = (data, param = "") => {
  const url = getAPIUrl(endpoint.updateWithdrawStatus, param);
  return Post(url, data);
};
export const getwalletDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getwalletDetails, param);
  return Get(url);
};
export const getTodayWithdrwalDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getTodayWithdrwalDetails, param);
  return Get(url);
};
export const addFund = (data, param = "") => {
  const url = getAPIUrl(endpoint.addFund, param);
  return PostFormData(url, data, true);
};
export const addnews = (data, param = "") => {
  const url = getAPIUrl(endpoint.addnews, param);
  return Post(url, data);
};
export const battle_detail = (param = "") => {
  const url = getAPIUrl(endpoint.battle_detail, param);
  return Get(url);
};
export const getSearchTodayWithdrawRequests = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchTodayWithdrawRequests, param);
  return Get(url);
};
export const gettournamentDetails = (param = "") => {
  const url = getAPIUrl(endpoint.gettournamentDetails, param);
  return Get(url);
};
export const getliveBattleDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getliveBattleDetails, param);
  return Get(url);
};
export const getSearchLiveBattle = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchLiveBattle, param);
  return Get(url);
};
export const getSearchHoldBattle = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchHoldBattle, param);
  return Get(url);
};
export const getcompleteBattleDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getcompleteBattleDetails, param);
  return Get(url);
};
export const getSearchtermCompleteBattle = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchtermCompleteBattle, param);
  return Get(url);
};
export const getRoomCodeCompleteBattle = (param = "") => {
  const url = getAPIUrl(endpoint.getRoomCodeCompleteBattle, param);
  return Get(url);
};
export const getRoomCodeLiveBattle = (param = "") => {
  const url = getAPIUrl(endpoint.getRoomCodeLiveBattle, param);
  return Get(url);
};
export const getgameRules = (param = "") => {
  const url = getAPIUrl(endpoint.getgameRules, param);
  return Get(url);
};
export const deleteRule = (param = "") => {
  const url = getAPIUrl(endpoint.deleteRule, param);
  return Delete(url, param);
};
export const addrule = (data, param = "") => {
  const url = getAPIUrl(endpoint.addrule, param);
  return Post(url, data);
};
export const rule_edit = (data, param = "") => {
  const url = getAPIUrl(endpoint.rule_edit, param);
  return Put(url, data);
};
///////////////////////////

export const editSettings = (data, param = "") => {
  const url = getAPIUrl(endpoint.editSettings, param);
  return Put(url, data);
};
export const getSettings = (param = "") => {
  const url = getAPIUrl(endpoint.getSettings, param);
  return Get(url);
};
// export const exportDataToExcel = (param = '') => {
//   const url = getAPIUrl(endpoint.exportDataToExcel,param)
//   return Get(url)
// }

// export const editCustomer = (data, param = '') => {
//   const url = getAPIUrl(endpoint.editCustomer, param)
//   return Put(url, data)
// }

// export const getDocImage=(data,param="")=>{
//   const url = getAPIUrl(endpoint.getDocImage,param);
//   return PostFormData(url,data,true);
// }

export const bankDetail = (param = "") => {
  const url = getAPIUrl(endpoint.bankDetail, param);
  return Get(url);
};

export const getholdbattles = (param = "") => {
  const url = getAPIUrl(endpoint.getholdbattles, param);
  return Get(url);
};

export const recharge_detail = (param = "") => {
  const url = getAPIUrl(endpoint.recharge_detail, param);
  return Get(url);
};
export const refferalPenalty_detail = (param = "") => {
  const url = getAPIUrl(endpoint.refferalPenalty_detail, param);
  return Get(url);
};
export const getSearchRefferalPenalty = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchRefferalPenalty, param);
  return Get(url);
};
export const updatePaymentStatus = (data, param = "") => {
  const url = getAPIUrl(endpoint.updatePaymentStatus, param);
  return Put(url, data);
};
