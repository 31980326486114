import { useEffect, useState } from "react";
import { Divider, Image, Table, Tag } from "antd";
import { Pagination, Spin } from "antd";
import { rechargedetail } from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime } from "../../../utils";
import { useLocation } from "react-router-dom";

const CompleteRechargeHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const location = useLocation(true);
  const Details = location.state?.Fill;

  function rechargeHistory() {
    setIsLoading(true);
    rechargedetail(`?page=${currentPage}&limit=10`)
      .then((response) => {
        setData(response.data.Data);
        setcurrentPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    rechargeHistory(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (data, record) => {
        return (
          <Tag
            className="text-xs flex w-full justify-center bg-rgbaprimary2 border-rgbaprimary5"
            title={record?.user_id?._id}
          >
            {" "}
            {record?.user_id?.name}
          </Tag>
        );
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (data, record) => {
        return <p title={record?.user_id?._id}> {record?.user_id?.mobile}</p>;
      },
    },

    {
      title: " Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Balance Before (₹)",
      dataIndex: "balance_before",
      key: "balance_before",
    },
    {
      title: "Balance After (₹)",
      dataIndex: "balance_after",
      key: "balance_after",
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (data, record) => {
        return (
          <Tag className="text-xs flex w-full justify-center bg-rgbaprimary2 border-rgbaprimary5">
            {" "}
            {record?.transaction_type}
          </Tag>
        );
      },
    },
    {
      title: "Amount type",
      dataIndex: "amount_type",
      key: "amount_type",
      render: (data, record) => {
        return record.amount_type === "cr" ? (
          <Tag className="text-xs flex w-1/2 justify-center bg-rgbaprimary2 border-rgbaprimary5">
            Credit
          </Tag>
        ) : (
          <Tag className="text-xs flex w-1/2 justify-center bg-rgbaprimary2 border-rgbaprimary5">
            Debit
          </Tag>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => (
        <p className="  rounded-md justify-center text-center w-1/2 cursor-pointer">
          {ExtractDate(record.createdAt)}•{ExtractTime(record.createdAt)}
        </p>
      ),
    },
  ];
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            Complete Recharge History
          </div>

          <div>
            <Divider
              orientation="center"
              className="fs-4  theme-darkt fw-semi-bold"
            >
              Recharge History
            </Divider>
          </div>
          <div
            style={{ maxHeight: "500px", overflow: "auto" }}
            className="bg-white"
          >
            <Table
              bordered={true}
              columns={columns}
              dataSource={data}
              pagination={false}
              className="w-screen sm:w-screen "
            />
          </div>
          <Pagination
            className="mt-2  w-full "
            onChange={handlePageChange}
            current={currentPage}
            defaultCurrent={1}
            total={10 * totalPage}
            showSizeChanger={false}
          />
        </>
      )}
    </>
  );
};

export default CompleteRechargeHistory;
