export const endpoint = {
  loginApi: `admin/login`,
  logout: `admin/log-out`,

  //Dashboard
  dashboard_count: `admin/adminDashbordCount`,

  // //KYC
  getKycRequest: `/admin/getkycRequests`,
  updateKYCStatus: `/admin/takeActionOnKYCrequest/`,
  getSearchKyc: `/admin/searchKyc?searchString=`,
  //customers
  fetchUser: `/admin/fetch-user-list`,
  recharge_detail: `/user/userRechargeHistory/`,
  putremoveRefree: `/admin/uesrReferalRemove?user_id=`,
  putWalletAction: `admin/userWalletActionByAdmin?action=`,
  getSearchuser: `/admin/searchUser?searchString=`,
  modifyStatus: `/admin/ActiveDeactive/`,
  //FundRequets
  getFundDetails: `/admin/fund-requests`,
  updateFundStatus: `/admin/take-fund-action`,
  fund_detail: `/admin/ManualFundHistory`,
  rechargedetail:`/admin/AlluserRechargeHistory`,
  //Withdraw Requests
  getwithdrawDetails: `/admin/fetch-withdraw-request?status=under_process`,
  updateWithdrawStatus: `/admin/take-withdraw-action`,
  getSearchWithdrawRequests: `/admin/searchTodaywallet/normal?searchString=`,
  //News
  addnews: `admin/AddNews`,
  getTournamentNews: `user/getNews?type=tournament_news`,
  getGeneralNews: `user/getNews?type=General_news`,
  getWithdrawalNews: `user/getNews?type=withdraw_news`,
  getFundNews: `user/getNews?type=fund_news`,
  deleteNews: `/admin/Delete_News/`,
  news_edit: `/admin/UpdateNewsbyId`,

  //Battle Requests
  getbattleDetails: `admin/battle-requests?action=`,
  updateActionStatus: `/admin/update-battle-request/`,
  getSearchCancel: `admin/searchbattleRequest?searchString=`,
  getSearchIwin: `admin/searchbattleRequest?searchString=`,
  //WalletDetails
  getwalletDetails: `admin/get-wallet-transaction`,
  //battle details
  battle_detail: `user/userBattleHistory`,
  //Settings
  getSettings: `/admin/getAdminsettings`,
  editSettings: `admin/UpdateAdminSettings`,

  //TournamentDetails
  gettournamentDetails: `/admin/tournaments?is_live=`,
  tournament_edit: `/admin/UpadteTournamnet/`,
  //BattleDetails
  getliveBattleDetails: `/admin/getbattles?status=Running`,
  getSearchLiveBattle: `/admin/searchBattle?key=live`,
  getRoomCodeLiveBattle: `/admin/searchBattle?key=Running&roomCode=`,
  //holdBattleDetails:
  getholdbattles: `/admin/holdBattleList`,
  getSearchHoldBattle: `/admin/searchBattle?key=hold`,
  //TodayWithdrwal
  getTodayWithdrwalDetails: `admin/TodayWithdrawal`,
  getSearchTodayWithdrawRequests: `/admin/searchTodaywallet/Today?searchString=`,
  //completedBattle
  getcompleteBattleDetails: `admin/getCompleteBattles`,
  getSearchtermCompleteBattle: `/admin/searchCompleteBattles?searchString=`,

  getRoomCodeCompleteBattle: `/admin/searchCompleteBattles?roomCode=`,
  // refferalPenalty_detail
  getSearchRefferalPenalty: `/admin/Search_penalty_bonus_Details?searchString=`,
  refferalPenalty_detail: `/admin/referal_penalty_bonus_Details`,
  //gamerules_detail
  getgameRules: `/user/getRules`,
  deleteRule: `/admin/Delete_Rule/`,
  addrule: `/admin/AddRule`,
  rule_edit:`/admin/UpdateRulebyId`,
  // getDocImage: `/customers/data-upload`,
  // exportDataToExcel:`/customers/export/csv`,
};
