import PrivateAuth from "./PrivateAuth";
import KycDetails from "../Views/Main Panel/KYCDetails";
import Users from "../Views/Main Panel/Users";
import WithdrawRequests from "../Views/Main Panel/WithdrawRequests";
import BattleHistory from "../Views/Main Panel/BattleHistory";
import WalletDetails from "../Views/Main Panel/WalletDetails";
import TournametDetails from "../Views/Main Panel/TournamentDetails";
import LiveBattles from "../Views/Main Panel/LiveBattles";
import BattleRequests from "../Views/Main Panel/BattleRequests";
import News from "../Views/Main Panel/AddNews";
import Dashboard from "../Views/Main Panel/Dashboard";
import Settings from "../Views/Main Panel/Settings";
import HoldBattles from "../Views/Main Panel/HoldBattles";
import TodayWithdrawl from "../Views/Main Panel/TodayWithdrawl";
import RechargeHistory from "../Views/Main Panel/Users/RechargeHistory";
import RefferalPenalty from "../Views/Main Panel/RefferalPenalty";
import CompleteBattles from "../Views/Main Panel/CompleteBattles";
import FundRequests from "../Views/Main Panel/FundRequests";
import FundHistory from "../Views/Main Panel/FundRequests/FundHistory";
import CompleteRechargeHistory from "../Views/Main Panel/FundRequests/CompleteRechargeHistory";
import GameRules from "../Views/Main Panel/GameRules";
const privateAdminRoute = () => {
  return [
    {
      element: <PrivateAuth />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/kycDetails",
          element: <KycDetails />,
        },
        {
          path: "/battleHistory",
          element: <BattleHistory />,
        },
        {
          path: "/liveBattles",
          element: <LiveBattles />,
        },

        {
          path: "/users",
          element: <Users />,
        },
        {
          path: "/fundRequests",
          element: <FundRequests />,
        },
        {
          path: "/fundHistory",
          element: <FundHistory />,
        },
        {
          path: "/completeRechargeHistory",
          element: <CompleteRechargeHistory />,
        },
        {
          path: "/withdrawRequests",
          element: <WithdrawRequests />,
        },
        {
          path: "/battleRequests",
          element: <BattleRequests />,
        },
        {
          path: "/walletDetails",
          element: <WalletDetails />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/liveTournaments",
          element: <TournametDetails />,
        },
        {
          path: "/holdBattles",
          element: <HoldBattles />,
        },
        {
          path: "/addNews",
          element: <News />,
        },
        {
          path: "/todayWithdrawl",
          element: <TodayWithdrawl />,
        },
        {
          path: "/rechargeHistory",
          element: <RechargeHistory />,
        },
        {
          path: "/refferalPenaltyBonus",
          element: <RefferalPenalty />,
        },
        {
          path: "/completeBattle",
          element: <CompleteBattles />,
        },
        {
          path: "/gameRules",
          element: <GameRules/>,
        },
      ],
    },
  ];
};

export default privateAdminRoute;
