import { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Space } from "antd";
import { dashboard_count, overdue_loans } from "../../../services/apiFunctions";
import { ExtractDate, formatNumber } from "../../../utils";
const Dashboard = () => {
  const [currentPage, setcurrentPage] = useState(1);
  const [userCount, setUserCount] = useState("");
  const [battleCount, setBattleCount] = useState("");
  const [running_battle_count, setRunningBattleCount] = useState("");
  const [holdBattleCount, setHoldBattleCount] = useState("");
  const [NewUserCount, setNewUserCount] = useState("");
  const [Total_wallet_balance, setTotal_wallet_balance] = useState("");
  const [Total_Income, setTotal_Income] = useState("");
  const [Today_Total_Income, setToday_Total_Income] = useState("");
  const [today_withdrawal, settoday_withdrawal] = useState("");
  const [Total_monthly_withdrawal, setTotal_monthly_withdrawal] = useState("");
  const [Total_Recharge, setTotal_Recharge] = useState("");
  const [Today_Total_Recharge, setToday_Total_Recharge] = useState("");
  const [Monthly_Total_Recharge, setMonthly_Total_Recharge] = useState("");
  const[data,setData] = useState({})
  //   function dashboardTable(currentPage, type = "all") {
  //     overdue_loans(`?page=${currentPage}&limit=10&role=admin&type=${type}`)
  //       .then((response) => {
  //         setData(response.data.data);
  //         setcurrentPage(response.data.currentPage);
  //         setTotalPages(response.data.totalPages);
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setIsLoading(false);
  //       });
  //   }
  function DashBoardCount() {
    dashboard_count()
      .then((response) => {
        if (response.data.success) {
          setData(response.data?.Data);
        //   setUserCount(response.data?.Data.userCount);
        //   setBattleCount(response.data?.Data.battleCount);
        //   setRunningBattleCount(response.data?.Data.running_battle_count);
        //   setHoldBattleCount(response.data?.Data.HoldBattleCount);
        //   setNewUserCount(response.data?.Data.NewUserCount);
        //   setTotal_wallet_balance(response.data?.Data.Total_wallet_balance);
        //   setTotal_Income(response.data?.Data.Total_Income);
        //   setToday_Total_Income(response.data?.Data.Today_Total_Income);
        //   settoday_withdrawal(response.data?.Data.today_withdrawal);
        //   setTotal_monthly_withdrawal(
        //     response.data?.Data?.Total_monthly_withdrawal
        //   );
        //   setTotal_Recharge(response.data?.Data.Total_Recharge);
        //   setToday_Total_Recharge(response.data?.Data.Today_Total_Recharge);
        //   setMonthly_Total_Recharge(response.data?.Data.Monthly_Total_Recharge);
        }
      })
      .catch((error) => {});
  }
  useEffect(() => {
    // dashboardTable(currentPage, type);
    DashBoardCount();
  }, [currentPage]);
  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
  };



  return (
    <>
      {/* {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : ( */}
      <>
        <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
          Dashboard
        </div>

        
        <div>
          <div className="container mx-auto px-4">
            <div className="lg:grid grid-cols-2 gap-5">
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="grid grid-cols-[40%_60%] ">
                  <div className="flex justify-center items-center">
                    <div>
                      <div className="text-base text-primary">Total User</div>
                      <div className="text-6xl font-semibold text-primary">
                        {" "}
                        {data?.userDashboard?.userCount}
                      </div>
                    </div>
                  </div>
                  <div className="border-l-2 pl-5 border-primary">
                    <div className="text-left my-2">
                      <span className="w-[90%] inline-block text-primary">
                        Today User Count:
                      </span>{" "}
                      <span className="font-semibold text-primary">
                        {" "}
                        {formatNumber(data?.userDashboard?.todayUserCount)}
                      </span>
                    </div>
                    <div className="text-left my-2">
                      <span className="w-[90%] inline-block text-primary">
                    Current Month User Count:
                      </span>{" "}
                      <span className="font-semibold text-primary">
                        {" "}
                        {formatNumber(data?.userDashboard?.currentMonthUserCount)}
                      </span>
                    </div>
                    {/* <div className="text-left my-2">
                      <span className="w-[90%] inline-block text-primary">
                        Closed Contests:
                      </span>{" "}
                      <span className="font-semibold text-primary">
                        {data?.userDashboard?.TotalClosedContest}
                      </span>
                    </div> */}
                    {/* <div className="text-left my-2">
                      <span className="w-[90%] inline-block">
                        On Hold Contests:
                      </span>{" "}
                      <span className="font-semibold">1</span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="grid grid-cols-[40%_50%] h-full">
                  <div className="flex justify-center items-center">
                    <div>
                      <div className="text-base text-primary">Total Battle</div>
                      <div className="text-6xl font-semibold text-primary">
                        {" "}
                        {formatNumber(data?.battleDashboard?.battleCount)}
                      </div>
                    </div>
                  </div>
                  <div className="border-l-2 pl-5 border-primary">
                    <div className="text-left my-2">
                      <span className="w-[90%] inline-block text-primary">
                        Today Battle Count:
                      </span>{" "}
                      <span className="font-semibold text-primary">
                        {" "}
                        {formatNumber(data?.battleDashboard?.todayBattleCount)}
                      </span>
                    </div>
                    <div className="text-left my-2">
                      <span className="w-[90%] inline-block text-primary">
                       Running Battle Count:
                      </span>{" "}
                      <span className="font-semibold text-primary">
                        {" "}
                        {formatNumber(data?.battleDashboard?.runningBattleCount)}
                      </span>
                      <div className="text-left my-2">
                      <span className="w-[90%] inline-block text-primary">
                       Hold Battle Count:
                      </span>{" "}
                      <span className="font-semibold text-primary">
                        {" "}
                        {formatNumber(data?.battleDashboard?.holdBattleCount)}
                      </span>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-5">
              {/* <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md ">
                <div className="text-base mb-3 flex justify-center text-primary">
                  Total Winning Amount
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                  {" "}
                  {data?.incomeDashboard?.TotalWinningAmount}
                </div>
              </div> */}
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="text-base mb-3 flex justify-center text-primary">
                Wallet Balance 
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                  {" "}
                  {formatNumber(data?.incomeDashboard?.totalWalletBalance)}
                </div>
              </div>
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="text-base mb-3 flex justify-center text-primary">
                  Total Income
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                {formatNumber(data?.incomeDashboard?.totalIncome)}
                </div>
              </div>
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="text-base mb-3 flex justify-center text-primary">
                  Today Total Income
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                  {" "}
                  {formatNumber(data?.incomeDashboard?.todayTotalIncome)}
                </div>
              </div>
            </div>
            <div className="mt-3 grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5">
             
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="text-base mb-3 flex justify-center text-primary">
               Total Recharge
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                  {" "}
                  {formatNumber(data?.rechargeAndWithdrawalDashboard?.totalRecharge)}
                </div>
              </div>
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="text-base mb-3 flex justify-center text-primary">
                  Today Total Recharge
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                {formatNumber(data?.rechargeAndWithdrawalDashboard?.todayTotalRecharge)}
                </div>
              </div>
              {/* <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="text-base mb-3 flex justify-center text-primary">
                  Total Monthly Recharge
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                  {" "}
                  {formatNumber(data?.rechargeAndWithdrawalDashboard?.monthlyTotalRecharge)}
                </div>
              </div> */}
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="text-base mb-3 flex justify-center text-primary">
                  Today Withdrawal
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                  {" "}
                  {formatNumber(data?.rechargeAndWithdrawalDashboard?.todayWithdrawal)}
                </div>
              </div>
              <div className="bg-white p-4 my-3 shadow-md shadow-rgbaprimary5 rounded-md">
                <div className="text-base mb-3 flex justify-center text-primary">
                 Monthly Withdrawal
                </div>
                <div className="text-4xl font-semibold text-primary flex justify-center">
                  {" "}
                  {formatNumber(data?.rechargeAndWithdrawalDashboard?.totalMonthlyWithdrawal)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default Dashboard;
